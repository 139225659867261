import { MouseEvent, useState } from 'react'
import { Container } from "reactstrap";

import { get } from "../../services/apiUnit";
import { UnitInput, IApiResponse } from 'src/types';


import { DataFlow, MCLabel } from "../../components/CommonForBoth/Entries/index";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate, useParams, useLoaderData, useSubmit, redirect } from "react-router-dom";
import DeleteModal from "../../components/CommonForBoth/DeleteModal";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const UnitView = () => {
    document.title = "Unit Detail | Fatoura - Admin";
    const submit = useSubmit();
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);

    const { unitId } = useParams();
    const { data: unit } = useLoaderData() as IApiResponse<UnitInput> || {};

    //commands
    const onEdit = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        navigate(`/units/${unitId}/edit`);
    }

    const onHandleDelete = () => {
        setRemoveModal(false);
        submit(null, { method: "delete" });
    }

    const onDelete = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        setRemoveModal((value) => !value);
    }

    return (
        <div className="page-content">
            <DeleteModal showModal={removeModal} setModal={setRemoveModal} onHandleDelete={onHandleDelete} />
            <Container fluid>
                <Breadcrumbs title="Units" breadcrumbItem="Unit Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} onEdit={onEdit} onDelete={onDelete} isEditMode={false} formName={"unit-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row >
                            <Col>
                                <h5 className="font-size-15 mb-1">Name: </h5>
                                <p className="text-muted">{unit.name}</p>
                            </Col>
                            <Col>
                                <div style={{ textAlign: "right" }} >
                                    <h5 className="d-flex justify-content-end font-size-15 mb-1">العنوان: </h5>
                                    <p className="text-muted">{unit.nameAr}</p>
                                </div>
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Unit Number' value={unit.unitNumber} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Zone' value={unit.zone} />
                            </Col>
                            <Col>
                                <MCLabel label='Building' value={unit.building} />
                            </Col>
                        </Row>


                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const loader = async ({ params }: any) => {
    if (params.unitId) {
        const unit = await get(params.unitId);
        return unit;
    }

    return null;
}

export const action = async ({ request, params }: any) => {
    let unitId: string = params.unitId;
    //await remove(unitId);
    return redirect(`/units`);
}

export default UnitView;