
import { IPagination, IApiResponse, IAccountProperties } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "accounts";
const LIST_BASIC_URL = BASE_RESOURCE + "/properties/list-basic";

const api = new appApi();

export const listBasic = (): Promise<IApiResponse<IAccountProperties[]>> => {
    return api.list<IAccountProperties>(LIST_BASIC_URL, {} as IPagination, { "lid": 1, "nocache": false })
}