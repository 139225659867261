
import { IPagination, IEventList, EventInput, IPostResponse, IApiResponse } from "../types/index";
import { appApi } from "./api/api";


const BASE_RESOURCE = "events";
const LIST_URL = BASE_RESOURCE+"/list";
const GET_URL = BASE_RESOURCE+"/{id}/get";
const ADD_URL = BASE_RESOURCE+"/add";
const UPDATE_URL = BASE_RESOURCE+"/{id}/update";
const DELETE_URL = BASE_RESOURCE+"/{id}/delete";


const api = new appApi();

export const list =  (pagination: IPagination): Promise<IApiResponse<IEventList[]>> => {

    const li = api.list<IEventList>(LIST_URL, pagination, {  });
    
    return  li;

}

export const get = async (id: number): Promise<IApiResponse<EventInput>> => {
    const url = GET_URL.replace("{id}", id.toString());
    return await api.get<EventInput>(url);
}

export const add = async (event: EventInput): Promise<number> => {
    const response = await api.post<EventInput, IPostResponse>(ADD_URL, event);
    return response.data.id as number;
};

export const update = async (id: number, event: EventInput) => {
    const url = UPDATE_URL.replace("{id}", id.toString());
    await api.update<EventInput, {}>(url, event);
    return;
};

export const remove = async (id: number) => {
    const url = DELETE_URL.replace("{id}", id.toString());
    await api.delete(url);
    return;
};