import { Container } from "reactstrap";

import { PaymentInput, IApiResponse } from 'src/types';
import { CurrencyTypeObject } from "../../constants";

import { get } from "../../services/apiPayment";

import { DataFlow, MCLabel, NumberFormatCol } from "../../components/CommonForBoth/Entries/index";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useLoaderData } from "react-router-dom";
//import DeleteModal from "../../components/CommonForBoth/DeleteModal";
import usePaymentData from "./hook";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PaymentView = () => {
    document.title = "Payment Detail | Fatoura - Admin";
    //const { PaymentId } = useParams();
    const { data: payment } = useLoaderData() as IApiResponse<PaymentInput> || {};
    //const [removeModal, setRemoveModal] = useState<boolean>(false);
    const { services } = usePaymentData();

    const serviceName = services?.find(x => x.id === payment.serviceId)?.name;

    return (
        <div className="page-content">

            <Container fluid>
                <Breadcrumbs title="Payments" breadcrumbItem="Payment Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} isEditMode={false} formName={"Payment-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row >
                            <Col>
                                <MCLabel label='Inv#' value={payment.invNo} />
                            </Col>
                            <Col>
                                <MCLabel label='Ref #' value={payment.refNo} />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Date' value={payment.date} />
                            </Col>
                            <Col>
                                {/* <MCLabel label='Unit' value={unitName} /> */}
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Currency' value={CurrencyTypeObject[payment.currencyId].symbol} />
                            </Col>
                            <Col>
                                <NumberFormatCol currencyId={payment.currencyId} value={payment.amount} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Service' value={serviceName} />
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const loader = async ({ params }: any) => {
    if (params.paymentId) {
        const payment = await get(Number(params.paymentId))
        return payment;
    }

    return null;
}


export default PaymentView