import { MouseEvent, useState } from 'react'
import { Container } from "reactstrap";

import { RequestInput, IApiResponse } from 'src/types';
import { get, remove } from "../../services/apiRequest";

import { DataFlow, MCLabel } from "../../components/CommonForBoth/Entries/index";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate, useParams, useLoaderData, useSubmit, redirect } from "react-router-dom";
import DeleteModal from "../../components/CommonForBoth/DeleteModal";
import userequestData from "./hook";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const RequestView = () => {
    document.title = "Request Detail | Fatoura - Admin";
    const { requestId } = useParams();
    const { data: request } = useLoaderData() as IApiResponse<RequestInput> || {};
    const submit = useSubmit();
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const { units, services } = userequestData();

    const unitName = units?.find(x => x.id === request.unitId)?.name;
    const serviceName = services?.find(x => x.id === request.serviceId)?.name;

    //commands
    const onEdit = (request: MouseEvent<HTMLButtonElement>) => {
        request.preventDefault();
        navigate(`/requests/${requestId}/edit`);
    }

    const onHandleDelete = () => {
        setRemoveModal(false);
        submit(null, { method: "delete" });
    }

    const onDelete = (request: MouseEvent<HTMLButtonElement>) => {
        request.preventDefault();
        setRemoveModal((value) => !value);
    }

    return (
        <div className="page-content">
            <DeleteModal showModal={removeModal} setModal={setRemoveModal} onHandleDelete={onHandleDelete} />
            <Container fluid>
                <Breadcrumbs title="Requests" breadcrumbItem="Request Details" routePath="/requests"/>
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} onEdit={onEdit} onDelete={onDelete} isEditMode={false} formName={"request-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row >
                            <Col>
                                <MCLabel label='Inv#' value={request.invNo} />
                            </Col>
                            <Col>
                                <MCLabel label='Ref #' value={request.refNo} />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Date' value={request.date} />
                            </Col>
                            <Col>
                                <MCLabel label='Unit' value={unitName} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Service' value={serviceName} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Description' value={request.description} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const loader = async ({ params }: any) => {
    if (params.requestId) {
        const event = await get(Number(params.requestId))
        return event;
    }

    return null;
}

export const action = async ({ request, params }: any) => {
    let requestId: number = Number(params.requestId);
    await remove(requestId);
    return redirect(`/requests`);
}

export default RequestView