import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationENG from "./locales/eng/translation.json";
import { SetLocalAsString, LocalKeys, GetLocalAsString } from "./services/localStorage";

// the translations
const resources: any = {
  eng: {
    translation: translationENG,
  },
  ar: {
    translation: translationENG,
  }
};

const language: any = GetLocalAsString(LocalKeys.GLOBAL_LANGUAGE); //localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  SetLocalAsString(LocalKeys.GLOBAL_LANGUAGE, "en");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: GetLocalAsString(LocalKeys.GLOBAL_LANGUAGE) || "en",
    fallbackLng: "ar", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });


export default i18n;