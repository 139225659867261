import { useState, useEffect } from "react";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout
} from "@fluentui/react-components";

import useInputUnitData from "../../../hooks/useInputUnitData";
import { Card, CardBody } from 'reactstrap';
import { IUnitResidentList } from 'src/types';

interface MCInputUserUnitViewProps {
    inputUserUnitIds?: (number | undefined)[],
}

const columns = [
    { columnKey: "unitNumber", label: "Unit Number" },
    { columnKey: "zone", label: "Zone" },
    { columnKey: "building", label: "Building" },
    { columnKey: "name", label: "Name" },
];

export const MCInputUserUnitView = ({ inputUserUnitIds }: MCInputUserUnitViewProps) => {

    const { units } = useInputUnitData();
    const [inputUnits, setInputUnits] = useState<IUnitResidentList[]>([]);
    
    useEffect(() => {
        if (inputUserUnitIds) {
            let unitsToBeAdded: IUnitResidentList[] = [];
            inputUserUnitIds.forEach((unitId: number | undefined) => {
                const selectedunit = units?.find(x => x.id === unitId);
                if (selectedunit) {
                    unitsToBeAdded.push(selectedunit);
                }
            });

            setInputUnits(() => unitsToBeAdded);
        }
    }, [units, inputUserUnitIds]);

    return (

        <Card>
            
            <CardBody>
                <Table arial-label="Default table" >
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell key={column.columnKey} >
                                    <strong>{column.label}</strong>
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody  >
                        {(!inputUnits ? [] : inputUnits).slice(0, 5).map((unit) => (
                            <TableRow key={unit.id} >
                                <TableCell>
                                    <TableCellLayout>
                                        {unit.unitNumber}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout>
                                        {unit.zone}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {unit.building}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {unit.name}
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </CardBody>
        </Card>


    )
}
