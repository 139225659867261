import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

interface IDeleteModalPropes {
    showModal: boolean,
    setModal: any,
    onHandleDelete(): any
}

const DeleteModal = (props: IDeleteModalPropes) => {
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_center() {
        props.setModal(!props.showModal);
        removeBodyCss();
    }

    return (
        <Modal isOpen={props.showModal} toggle={() => { tog_center(); }} centered={true}>
            <ModalHeader>
                <button
                    type="button"
                    onClick={() => { props.setModal(false); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </ModalHeader>
            <ModalBody>
                <div className="text-center">
                    <div className="text-danger">
                        <i className="fa fa-trash-alt display-5"></i>
                    </div>
                    <div className="mt-4">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this product ?</p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <Button type="button" color="light" className="btn w-sm" onClick={tog_center}>Close</Button>
                    <Button type="button" color="danger" variant="danger" className="btn w-sm btn-hover" id="delete-record" onClick={props.onHandleDelete}>Yes, Delete It!</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default DeleteModal