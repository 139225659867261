import { useMemo } from "react";
import dayjs from 'dayjs';
import { useLoaderData, Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { useNavigate } from "react-router-dom";
import { list as listPayment } from "../../services/apiPayment";

import { IApiResponse, IPagination, IPaymentList } from "../../types/index";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";
import { StatusIcon, NumberFormatCol } from "../../components/CommonForBoth/Entries/index";

const Payments = () => {
    document.title = "Payments | Fatoura - Admin";
    const { data: list } = useLoaderData() as IApiResponse<IPaymentList[]>;

    const columns = useMemo<ColumnDef<IPaymentList>[]>(
        () => [
            {
                accessorKey: "invNo",
                id: 'invNo',
                //cell: info => info.getValue(),
                cell: ({ cell }: any) => <Link to={`/payments/${cell?.row?.original.id}`} className="medium">{`${cell.getValue()}`}</Link>,
                header: () => <span>InvNo</span>,
                footer: props => props.column.id,
                size: 100
            },

            {
                header: () => <span>Date</span>,
                accessorKey: "date",
                id: 'Date',
                cell: props => dayjs(props.getValue() as Date).format("DD/MM/YYYY"),
                size: 50
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Unit Number</span>,
                accessorKey: "unitFullNumber",
                id: 'unitFullNumber',
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Amount</span>,
                accessorKey: "amount",
                id: 'amount',
                cell: props  => <NumberFormatCol currencyId={props.cell.row.original.currencyId} value={props.getValue() as number}/>,
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Inv. Date</span>,
                accessorKey: "invoiceDate",
                id: 'invoiceDate',
                cell: props => dayjs(props.getValue() as Date).format("DD/MM/YYYY"),
                size: 50
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Inv. InvNo</span>,
                accessorKey: "invoiceInvNo",
                id: 'invoiceInvNo',
                //cell: info => info.getValue(),
                cell: ({ cell }: any) => <Link to={`/invoices/${cell?.row?.original.invoiceId}`} className="medium">{`${cell.getValue()}`}</Link>,
                footer: props => props.column.id,
                size: 100
            },

            {
                accessorKey: "service",
                id: 'service',
                cell: info => info.getValue(),
                header: () => <span>Service</span>,
                footer: props => props.column.id,
                size: 100
            },
            
            {
                header: () => <span>Status</span>,
                accessorKey: "status",
                id: 'status',
                cell: ({ cell }: any) => <StatusIcon statusId={cell?.row?.original.statusId} statusName={cell.getValue()} />,
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
        ],
        [])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Payments" breadcrumbItem="List" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {<TableContainer
                                    columns={columns}
                                    data={list}
                                    isGlobalFilter={true}
                                    isAddNewList={false}
                                    customPageSize={1}
                                    className="custom-header-css"
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export const loader = async () => {

    const pagination: IPagination = {
        pageNo: 0,
        pageSize: 10
    }

    const list = await listPayment(pagination);
    
    if (!list || list.data === null) {
        const empty: IApiResponse<IPaymentList[]> = {} as IApiResponse<IPaymentList[]>;
        empty.data= [];
        return empty;
      }
    
      return list;
}

export default Payments;
