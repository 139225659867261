
// action
import {
    profileSuccess, profileError
} from "./reducer";
 

export const editProfile: any = (user: any) => async (dispatch: any) => {
    try {
        let response;


        const data = await response;

        if (data) {
            dispatch(profileSuccess(data))
        }

    } catch (error) {
        dispatch(profileError(error))
    }
};

export const resetProfileFlag = (user: any) => async (dispatch: any) => {

}