
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { Textarea, Field } from "@fluentui/react-components";
import type { TextareaProps } from "@fluentui/react-components";
import { FieldValues, Path } from "react-hook-form";

interface MCTextAreaProps<T extends FieldValues> extends MCBaseInputProp<T>, TextareaProps {
    control?: any
}

export const MCTextAria = <T extends FieldValues>({ label, fieldName, registerForm, registerProps, control, errors, ...props }: MCTextAreaProps<T>) => {
    const { ref, ...registerInput } = registerForm(fieldName as Path<T>, { ...registerProps });
    const errorMessage = errors[fieldName]?.message;
    
   
    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>

                <Textarea {...props} ref={ref} {...registerInput} {...(errors ? { invalid: true } : {})}
                    resize="both" appearance="outline" aria-setsize={111} rows={8} cols={5} autoComplete="off"/>
            </Field>
        </FormGroup>
    )
}
