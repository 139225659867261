import React from 'react'
import { CurrencyTypeObject as currency } from "../../../constants/index";

interface NumberFormatColProps {
    value: number,
    currencyId: number
}

export const NumberFormatCol = ({ value, currencyId }: NumberFormatColProps) => {

    const currencyType = currency[currencyId].symbol;
    return (
        <span style={{float: "left"}}>{Intl.NumberFormat('en-US', {
            style: 'currency', currency: currencyType, currencySign: "accounting",
            maximumFractionDigits: 4
        }).format(value)}</span>
    )
}
