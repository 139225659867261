import { authApi } from "./api/apiAuth"
import { IAdminUser, AdminUserLoginModel } from "../types/adminUser";
//import { RemoveLocal } from ""

//LOGIN
export const LOGIN_URL = "login";
export const SIGNUP_URL = "";
const api = new authApi();

// Login Method
export const UserLogin = async (userName: string, password: string): Promise<IAdminUser> => {
    const data = {
        "userName": userName,
        "password": password
    };

    const response = await api.login<AdminUserLoginModel>(LOGIN_URL, data);
    return response.data.data.user;
};

export const SignUp = (data: any) => {
    return api.post(SIGNUP_URL, data)
        .then(response => {
            if (response.status >= 200 || response.status <= 299)
                return response.data;
            throw response.data;
        })
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message =
                            "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};


export const UserLogout = () => {
    return api.logout();
};

 
