import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from 'redux'

import layoutReducer from "./Layout/reducer"
 
//register
import registerReducer from "./Auth/Register/reducer";

//login
import loginReducer from "./Auth/Login/reducer";
 
// // User Profile 
import profileReducer from "./Auth/Profile/reducer";

// // Forget Password
import forgetPasswordReducer from "./Auth/Forgetpwd/reducer";


const rootReducer = combineReducers({
    Layout: layoutReducer,
    register: registerReducer,
    login: loginReducer,
    forgetPassword: forgetPasswordReducer,
    profile: profileReducer,
})

export const store = configureStore({ reducer: rootReducer, devTools: true });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default rootReducer