import { MouseEvent, useState } from 'react'
import { Container } from "reactstrap";

import { UserInput, IApiResponse } from 'src/types';
import { get, remove } from "../../services/apiUser";

import { DataFlow, MCLabel } from "../../components/CommonForBoth/Entries/index";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate, useParams, useLoaderData, useSubmit, redirect } from "react-router-dom";
import DeleteModal from "../../components/CommonForBoth/DeleteModal";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MCInputUserUnitView } from 'src/components/CommonForBoth/Entries/MCInputUserUnitView';

const UserView = () => {
    document.title = "Payment Detail | Fatoura - Admin";
    const submit = useSubmit();
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);

    const { userCode } = useParams();
    const { data: user } = useLoaderData() as IApiResponse<UserInput> || {};

    //commands
    const onEdit = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        navigate(`/users/${userCode}/edit`);
    }


    const onHandleDelete = () => {
        setRemoveModal(false);
        submit(null, { method: "delete" });
    }

    const onDelete = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        setRemoveModal((value) => !value);
    }

    return (
        <div className="page-content">
            <DeleteModal showModal={removeModal} setModal={setRemoveModal} onHandleDelete={onHandleDelete}   />
            <Container fluid>
                <Breadcrumbs title="Users" breadcrumbItem="Users Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} onEdit={onEdit} onDelete={onDelete}  isEditMode={false} formName={"user-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row >
                            <Col>
                                <MCLabel label='Login Name' value={user.loginName} />
                            </Col>
                            <Col>
                                <MCLabel label='user Code' value={user.code} />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='First Name' value={user.firstName} />
                            </Col>
                            <Col>
                                <MCLabel label='Last Name' value={user.lastName} />
                            </Col>
                        </Row>

                        <Row >
                            <Col>
                                <MCLabel label='Email' value={user.email} />
                            </Col>
                            <Col>
                                <MCLabel label='PhoneNo' value={user.phoneNo} />
                            </Col>
                        </Row>

                        <Row >
                            <Col>
                                <MCInputUserUnitView inputUserUnitIds={user.units || [] as number[]} />
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const loader = async ({ params }: any) => {
    if (params.userCode) {
        const user = await get(params.userCode);
        return user;
    }

    return null;
}

export const action = async ({ request, params }: any) => {
    let userCode: string = params.userCode;
    await remove(userCode);
    return redirect(`/users`);
}

export default UserView;