import React, { useEffect, useState } from 'react'
import { Container } from "reactstrap";
import { FallingLines } from "react-loader-spinner";
interface ILoaderProps {
    isCenter: boolean
}

const Loader = ({ isCenter }: ILoaderProps) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 300);

        return () => clearTimeout(timer);
    }, [setShow])

    return (
        <div className="page-content">
            <Container fluid>
                <div className="d-flex align-items-center">
                    <FallingLines
                        visible={show}
                        height="80"
                        width="80"
                        color="#4fa94d"
                    />
                </div>

                {/* {
                    !isCenter && <Placeholder as="p" animation="glow" className="text-primary">
                        <Placeholder xs={12} />
                    </Placeholder>
                }
                {
                    isCenter && <Placeholder as="p" animation="glow" className="text-danger">
                        <Placeholder xs={12} />
                    </Placeholder>
                } */}

            </Container>
        </div>

    )
}

export default Loader