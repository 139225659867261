
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { UseFormSetValue, FieldValues, Path, PathValue } from "react-hook-form";
import { Field } from "@fluentui/react-components";
import CurrencyInput from 'react-currency-input-field';
import type { InputProps } from "@fluentui/react-components";
import { CurrencyTypeObject } from "../../../constants/index";

interface MCInputProps<T extends FieldValues> extends MCBaseInputProp<T>, InputProps {
    setValue: UseFormSetValue<T>,
    currencyId: number
}

export const MCAmount = <T extends FieldValues>({ label, fieldName, placeholder, registerForm, setValue, currencyId, registerProps, errors, ...props }: MCInputProps<T>) => {
    const name = fieldName ?? "";
    const { ref } = registerForm(name as Path<T>, { ...registerProps });
    const errorMessage = errors[fieldName]?.message;

    const amountSymbol = CurrencyTypeObject[currencyId].symbol
    const onChange = (value: any, name: any, values: any) => {
        if (setValue) {
            setValue(fieldName as Path<T>, value as PathValue<T, any>, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true
            });
        }
    };

    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>

                <CurrencyInput
                    name={fieldName}
                    ref={ref}
                    intlConfig={{ locale: 'en-US', currency: amountSymbol }}
                    placeholder={placeholder}
                    decimalsLimit={2}
                    onValueChange={onChange}
                    autoComplete="off"
                />
            </Field>
        </FormGroup>
    )
}
