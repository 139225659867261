import { redirect, Form, useNavigation, useSubmit, useLoaderData, useParams } from "react-router-dom";

import { Container, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { unitSchema, UnitInput, IApiResponse } from "../../types/index";
import { add, update, get } from "../../services/apiUnit";
import useUnitData from "./hook";

import { DevTool } from "@hookform/devtools";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataFlow, MCText2, MCCombobox } from "../../components/CommonForBoth/Entries/index";
import { makeStyles, Option } from '@fluentui/react-components';

const useStyles = makeStyles({
    option: {
        height: "32px",
    },
});

const UnitDetail = () => {
    document.title = "Unit Detail | Fatoura - Admin";
    const styles = useStyles();
    const navigation = useNavigation();
    const submit = useSubmit();
    const isSubmitting = navigation.state === "submitting";
    const { unitId } = useParams();
    const { data: unit } = useLoaderData() as IApiResponse<UnitInput> || {};

    const initValues = unitSchema.cast(unit || {});
    const {  control, register, handleSubmit, setValue, zones, buildings, errors } = useUnitData(initValues);
    ///////// methods

    //commands
    const onSubmit = (data: any, e: any) => {
        const method = !unitId || unitId === "" ? "post" : "put";
        submit(data, { method: method });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Unit" breadcrumbItem="Unit Details" />
            </Container>
            <Card>
                <CardHeader>
                    <DataFlow isSubmitting={isSubmitting} isEditMode={true} formName={"unit-form"} />
                </CardHeader>
                <CardBody>
                    <Form id="unit-form" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <MCText2 label="Name" fieldName="name" placeholder="enter name here" className="form-control" type="text"
                                    registerForm={register} errors={errors} control={control} />
                            </Col>
                            <Col >
                                <MCText2 label="اسم" fieldName="nameAr" placeholder="أدخل الاسم هنا" className="form-control" type="text"
                                    registerForm={register} errors={errors} control={control} style={{ textAlign: "right" }}
                                />
                            </Col>
                        </Row>
                        <Row className="gap-3mt-n1">
                            <Col >
                                <MCText2 label="Unit Number" fieldName="unitNumber" placeholder="enter unit number here"
                                    className="form-control" type="text" id="unitNumber"
                                    registerForm={register} errors={errors} control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MCCombobox fieldName="zone" label="Zone*" placeholder="Select zone" registerForm={register} control={control} setValue={setValue} errors={errors} items={zones || []}
                                    selectItem={(value: any) => zones?.find(x => x.name === value)?.name || ""}>
                                    {(zones || []).map((zone) => (
                                        <Option key={zone.name} text={zone.name} value={zone.name} className={styles.option}>
                                            <div >{zone.name}</div>
                                        </Option>
                                    ))}
                                </MCCombobox>
                            </Col>

                            <Col>
                                <MCCombobox fieldName="building" label="Building*" placeholder="Select building" registerForm={register} control={control} setValue={setValue} errors={errors} items={buildings || []}
                                    selectItem={(value: any) => buildings?.find(x => x.name === value)?.name || ""}>
                                    {(buildings || []).map((building) => (
                                        <Option key={building.name} text={building.name} value={building.name} className={styles.option}>
                                            <div >{building.name}</div>
                                        </Option>
                                    ))}
                                </MCCombobox>
                            </Col>

                        </Row>
                    </Form>
                    <DevTool control={control} />
                </CardBody>
            </Card>
        </div >
    );
}

export const action = async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    let unitId: number = params.unitId;
    if (data.id) {
        delete data.id;
    }

    const newInvoice = {
        ...data
    };

    if (request.method === "POST") {
        unitId = await add(newInvoice as UnitInput);
    } else if (request.method === "PUT") {
        await update(unitId, newInvoice as UnitInput);
    }
    return redirect(`/units/${unitId}`);
}

export const loader = async ({ params }: any) => {
    if (params.unitId) {
        const unit = await get(params.unitId)
        return unit;
    }

    return null;
}


export default UnitDetail