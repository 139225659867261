
export const LocalKeys = {
    AUTH_USER_KEY: "authUser",
    TOKEN_KEY: "jwtToken",
    GLOBAL_ACC_KEY: "global_account",
    GLOBAL_LANGUAGE: "I18N_LANGUAGE",
    GLOBAL_LANGUAGE_ID: "I18N_LANGUAGE_ID"
};

export const SetLocal = <TData>(key: string, data: TData) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const GetLocal = <TData>(key: string): TData => {
    const data = localStorage.getItem(key);
    if (data) {
        return JSON.parse(data) as TData;
    }
    return {} as TData;
}

export const SetLocalAsString = (key: string, data: string) => {
    localStorage.setItem(key, data);
}

export const GetLocalAsString = (key: string): string => {
    const data = localStorage.getItem(key);
    return data as string;
}

export const RemoveLocal = (key: string) => {
    localStorage.removeItem(key);
}
