import { useQuery } from '@tanstack/react-query';
import { listBasic as loadUnits } from "../../services/apiUnit";
import { listBasic as loadServices } from "../../services/apiService";
import { list as loadLookUps } from "../../services/apiMisc";
import { ELookUpType, CurrencyTypeObject } from "../../constants/index";
import { IUnitBasicList, IServiceBasicList, ICurrency, ILookUp } from "../../types/index";

function useInvoiceData() {

    ///////// methods
    const fetchUnits = async () => {
        const response = await loadUnits();
        return response.data;
    };

    const fetchServices = async () => {
        const response = await loadServices();
        return response.data;
    };

    const fetchCurrencies = async (): Promise<ICurrency[]> => {
        const response = await loadLookUps(ELookUpType.CURRENCY);
        return response.map((item: ILookUp) => ({
            ...item,
            symbol: CurrencyTypeObject[item.id].symbol
        }));
    };

    const { data: units } = useQuery<IUnitBasicList[], Error>({ queryKey: ["basic", "units"], queryFn: () => fetchUnits() })
    const { data: services } = useQuery<IServiceBasicList[], Error>({ queryKey: ["basic", "services"], queryFn: () => fetchServices() })
    const { data: currencies } = useQuery<ICurrency[], Error>({ queryKey: ["basic", "Currencies"], queryFn: () => fetchCurrencies() || [] })
    return { units, services, currencies }
}

export default useInvoiceData