import React from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle, UncontrolledDropdown
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { userSelector, selectedAccountSelector } from "../../../slices/selector";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { changeGlobalAccount } from "../../../slices/thunks";

const AccountSelector = () => {
    const dispatch = useDispatch();
    const user = useAppSelector(userSelector);
    const selectedAccount = useAppSelector(selectedAccountSelector);

    const changeSelectedAccount = (id: string) => {
        const account = user.accounts.find(x => x.id === id);
        dispatch(changeGlobalAccount(account));
    };

    return (
        <React.Fragment>
            <UncontrolledDropdown className="justify-content-between d-flex align-items-center">
                <DropdownToggle
                    type="button"
                    className="btn btn-dark btn-sm d-flex justify-content-center"
                    tag="a">
                    {selectedAccount.name + " "}
                    <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg-end">
                    {user.accounts.map(account => (
                        <DropdownItem to="#" key={account.id}
                            onClick={() => changeSelectedAccount(account.id)}>{account.name}</DropdownItem>
                    ))}

                </DropdownMenu>
            </UncontrolledDropdown>
        </React.Fragment>
    );
};

export default withTranslation()(AccountSelector);