import * as Yup from "yup";
import { ECurrency } from "../constants/index";

export const paymentSchema = Yup.object()
    .shape({
        date: Yup.date().default(new Date()).required("Please Enter Date").typeError("Please Enter Date"),
        invNo: Yup.string().default(""),
        refNo: Yup.string().default("").required("Please enter refno"),
        currencyId: Yup.number().default(ECurrency.Dinar).moreThan(0, "Please enter currency").required("Please enter currency"),
        invoiceId: Yup.number().default(0).moreThan(0, "Please enter unit").required("Please enter unit").typeError("Please enter a valid unit"),
        amount: Yup.number().default(0).moreThan(0, "Please enter total price").positive("Total price must be positive").required("Please enter total Price"),
        serviceId: Yup.number().default(0).moreThan(0, "Please enter service").required("Please enter unit"),
        statusId: Yup.number().default(0),
    });

export type PaymentInput = Yup.InferType<typeof paymentSchema>;

export interface IPaymentList {
    id: number,
    date: Date,
    invNo: string,
    currencyId: number,
    amount: number,
    statusId: number,
    status: string,
    invoiceId: number,
    invoiceDate: Date,
    invoiceInvNo: string,
    unitFullNumber: string
    service: string,
}