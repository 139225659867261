import React, { useState } from 'react'
import DropZone from "react-dropzone"
import { upload } from "../../services/apiFile";
import { CardBody, Button } from "reactstrap";
import { getImageFullPath } from "../../helpers/utils";


interface ImageProps {
    imageUrl: string
    setImageId: any,
}
const acceptedFiles = { 'image/jpeg': [] };

const UploadFile = ({ setImageId, imageUrl }: ImageProps) => {
    const [file, setFile] = useState(null);
    const [imagePrev, setImagePrev] = useState(imageUrl && imageUrl !== "");
    const hanldeUpload = async (acceptedFiles: any) => {
        if (!acceptedFiles || acceptedFiles.length === 0) {
            return;
        }

        let imageId = await upload(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
        setImageId(imageId);
    }

    const handleImageDelete = () => {
        setImageId("");
        setFile(null);
        setImagePrev(false);
    }

    return (
        <div>
            {imagePrev && <CardBody>
                <div className="d-flex align-items-center gap-1">
                    <img src={getImageFullPath(imageUrl)} className="img-fluid" alt="" />
                    <Button type="button" color="danger" onClick={handleImageDelete}>
                        <i className="far fa-trash-alt" />
                    </Button>
                </div>
            </CardBody>
            }


            {!imagePrev && file && (
                <img src={URL.createObjectURL(file)} className="img-fluid" alt="Uploaded file" />
            )}

            {
                !imagePrev && !file && (
                    <DropZone onDrop={hanldeUpload} accept={acceptedFiles} minSize={100} maxSize={3072000}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {

                            return (
                                <div className="dropzone">
                                    <div className="dz-message" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="mb-1">
                                            <i className="display-4 text-muted uil uil-cloud-upload"></i>
                                        </div>
                                        <h4>click to upload.</h4>
                                    </div>
                                </div>


                            );
                        }}

                    </DropZone>
                )
            }



        </div >
    )
}

export default UploadFile