import { useState } from 'react'
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { IItemList } from "../../../types/index";
import { Path, Controller } from "react-hook-form";
import type { UseFormSetValue, FieldValues, PathValue } from "react-hook-form";
import { Combobox, Field, makeStyles, shorthands } from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";


interface MCInputProps<T extends FieldValues> extends MCBaseInputProp<T>, ComboboxProps {
    setValue: UseFormSetValue<T>,
    control: any,
    items: IItemList[],
    selectItem?: any
}

const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        ...shorthands.gap("2px"),
        maxWidth: "200px",
    },
    listbox: {
        maxHeight: "300px",
        width: "450px"
    },
    option: {
        height: "32px",
    },
});

//https://react.fluentui.dev/?path=/docs/components-combobox--default#controlled
export const MCCombobox = <T extends FieldValues>({ label, fieldName, placeholder, control, setValue, items, registerProps, errors, selectItem, ...props }: MCInputProps<T>) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([""]);
    const errorMessage = errors[fieldName]?.message;
    const onOptionSelect: (typeof props)['onOptionSelect'] = (ev, data) => {
        setSelectedOptions(data.selectedOptions);
        setValue(fieldName as Path<T>, data.optionValue as PathValue<T, any>, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    };

    const styles = useStyles();
    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>
                <Controller
                    control={control}
                    name={fieldName}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Combobox
                            {...props}
                            ref={ref}
                            onChange={onChange}
                            value={!selectItem ? items?.find(x => x.id === Number(value))?.name || "" : selectItem(value)}
                            selectedOptions={selectedOptions}
                            onOptionSelect={onOptionSelect}
                            clearable
                            placeholder={placeholder}
                            size="medium"
                            maxLength={200}
                            listbox={{ className: styles.listbox }}
                            autoComplete="off"
                        >
                            {props.children}
                        </Combobox>
                    )}
                />


            </Field>
        </FormGroup>
    )
}

export default MCCombobox