import { useMemo } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { list as listUnit } from "../../services/apiUnit";

import { IApiResponse, IPagination, IUnitList } from "../../types/index";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";
import { Link } from "react-router-dom";

const UnitList = () => {
    document.title = "Units | Fatoura - Admin";
    const navigate = useNavigate();
    const { data: list } = useLoaderData() as IApiResponse<IUnitList[]>;

    const columns = useMemo<ColumnDef<IUnitList>[]>(
        () => [
            {
                header: () => <span>Number</span>,
                accessorKey: "unitNumber",
                id: 'unitNumber',
                cell: ({ cell }: any) => <Link to={`/units/${cell?.row?.original.id}`} className="medium">{`${cell.getValue()}`}</Link>,
                filterFn: "includesString",
                enableSorting: false,
                size: 70
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Zone</span>,
                accessorKey: "zone",
                id: 'zone',
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 100
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Building</span>,
                accessorKey: "building",
                id: 'building',
                //cell: props => props.getValue(),
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 100
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Name</span>,
                accessorKey: "name",
                id: 'name',
                cell: info => info.getValue(),
                //footer: props => props.column.id,
                size: 70
            },
            {
                header: () => <span>Full number</span>,
                accessorKey: "unitFullNumber",
                id: 'unitFullNumber',
                cell: info => info.getValue(),
                //footer: props => props.column.id,
                size: 70
            },  
        ],
        [])

    const handleAddNewClick = () => {
        navigate('/units/new');
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Units" breadcrumbItem="List" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {<TableContainer
                                    columns={columns}
                                    data={list}
                                    isGlobalFilter={true}
                                    isAddNewList={true}
                                    addNewText={"Add new unit"}
                                    handleAddNewClick={handleAddNewClick}
                                    customPageSize={1}
                                    className="custom-header-css"
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export const loader = async () => {

    const pagination: IPagination = {
        pageNo: 0,
        pageSize: 10
    }

    const list = await listUnit(pagination);

    if (!list || list.data === null) {
        const empty: IApiResponse<IUnitList[]> = {} as IApiResponse<IUnitList[]>;
        empty.data = [];
        return empty;
    }

    return list;
}

export default UnitList;
