
// action
import {
    apiError,
    loginSuccess,
    setGlobalAccount
} from "./reducer";
import { SetLocal, RemoveLocal, LocalKeys } from "../../../services/localStorage";
import { UserLogin, UserLogout } from "../../../services/apiAuthService";
import { IAccount } from "../../../types/index";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, setUserId } from "firebase/analytics";


// const registerToFirebase = () => {
//     const auth = getAuth();

//     onAuthStateChanged(auth, (user) => {
//         console.log(user);
//         if (user) {
//             const uid = user.uid; // Get the unique user ID from the Firebase user object
//             const analytics = getAnalytics();
//             setUserId(analytics, uid); // Set the user ID in Firebase Analytics

//             // You can also store the user ID in your database for future reference
//         } else {
//             // User is not signed in
//         }
//     });
// }


export const loginUser: any = (data: any, navigate: any) => async (dispatch: any) => {
    try {
        const response = UserLogin(data.email, data.password);
        const user = await response;

        if (user) {
            SetLocal(LocalKeys.AUTH_USER_KEY, user);
            if (user.accounts && user.accounts.length !== 0) {
                SetLocal(LocalKeys.GLOBAL_ACC_KEY, user.accounts[0]);
            }


            dispatch(loginSuccess(user));
            navigate("/events");
        }
    } catch (error: any) {
        dispatch(apiError(error));
    }
};


export const logoutUser: any = (navigate: any) => async (dispatch: any) => {
    try {
        UserLogout();

        RemoveLocal(LocalKeys.AUTH_USER_KEY);
        RemoveLocal(LocalKeys.GLOBAL_ACC_KEY);
        navigate("/login");
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const changeGlobalAccount: any = (account: IAccount) => {
    return (dispatch: any) => {
        dispatch(setGlobalAccount(account));
        SetLocal(LocalKeys.GLOBAL_ACC_KEY, account);
    }
};