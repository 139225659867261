import { useEffect } from "react";
import dayjs from 'dayjs';

import { redirect, Form, useNavigation, useSubmit, useLoaderData, useParams } from "react-router-dom";

import { invoiceSchema, InvoiceInput, IApiResponse } from "../../types/index";
import { add, update, get } from "../../services/apiInvoice";

import useInvoiceData from "./hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Card, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataFlow, MCText, MCCombobox, MCTextAria, MCAmount, MCDate } from "../../components/CommonForBoth/Entries/index";
import { Input, Field, makeStyles, Option } from '@fluentui/react-components';

//import { DevTool } from "@hookform/devtools";

const useStyles = makeStyles({
    option: {
        height: "32px",
    },
});

const InvoiceDetail = () => {
    document.title = "Event Detail | Fatoura - Admin";

    const styles = useStyles();
    const { invoiceId } = useParams();
    const navigation = useNavigation();
    const submit = useSubmit();

    const isSubmitting = navigation.state === "submitting";
    const { data: invoice } = useLoaderData() as IApiResponse<InvoiceInput> || {};
    const initValues = invoiceSchema.cast(invoice || {});
    const { control, register, getValues, handleSubmit, setValue, watch, formState: { errors } } = useForm<InvoiceInput>({
        resolver: yupResolver(invoiceSchema),
        defaultValues: initValues,
    });

    const { units, services, currencies } = useInvoiceData();

    ///////// methods

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "serviceId") {
                const selectedService = services?.find(x => x.id === Number(getValues("serviceId")));
                setValue("description", selectedService?.description || "");
            }
        })
        return () => subscription.unsubscribe()
    }, [services, watch, setValue, getValues]);

    //commands
    const onSubmit = (data: any, e: any) => {
        const method = !invoiceId || Number(invoiceId) === 0 ? "post" : "put";
        submit(data, { method: method });
    };

    const selectUnit = (value: number) => {
        return units?.find(x => x.id === Number(value))?.unitNumber || "";
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Details" />
            </Container>
            <Card>
                <CardHeader>
                    <DataFlow isSubmitting={isSubmitting} isEditMode={true} formName={"invoice-form"} />
                </CardHeader>
                <CardBody>
                    <Form id="invoice-form" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <input name="honeypot" className="visually-hidden" autoComplete="off"></input>
                            <Row className="gap-3mt-n1">
                                <Col >
                                    <FormGroup>
                                        <Field label="Inv #:">
                                            <Input name="invNo" placeholder="####" className="form-control" id="invno"
                                                type="text" value={invoice ? invoice.invNo : ""} disabled={true} />
                                        </Field>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <MCText label="Ref. #:" fieldName="refNo" placeholder="enter reference # here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>
                            </Row>

                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCDate label="Date" fieldName="date" placeholder="Select a date" className="form-control" id="date"
                                        registerForm={register} errors={errors} registerProps={{ valueAsDate: true }} selectedDateValue={initValues.date} setValue={setValue} />
                                </Col>
                                <Col>
                                    <MCCombobox fieldName="unitId" label="Unit*" placeholder="Select unit" registerForm={register} control={control} setValue={setValue} errors={errors}
                                        items={units || []}
                                        selectItem={selectUnit}
                                    >
                                        {(units || []).map((unit) => (
                                            <Option key={unit.id} text={unit.unitNumber} value={unit.id.toString()} className={styles.option}>
                                                <div >{unit.unitNumber}</div>
                                                <div >{unit.zone}</div>
                                                <div >{unit.building}</div>
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MCCombobox fieldName="currencyId" label="Currency" registerForm={register} control={control} setValue={setValue} errors={errors} items={currencies || []} >
                                        {(currencies || []).map((currency) => (
                                            <Option key={currency.id} text={currency.name} value={currency.id.toString()}
                                                className={styles.option}>
                                                <div >{currency.name}</div>
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                                <Col>
                                    <MCAmount label="TotalPrice" fieldName="totalPrice" className="form-control" currencyId={getValues("currencyId")} placeholder="enter amount"
                                        registerForm={register} setValue={setValue} errors={errors} />
                                </Col>
                            </Row>
                            <Row >
                                <Col md={6} sm={6}  >
                                    <MCCombobox fieldName="serviceId" label="Service" placeholder="Select service" control={control} items={services || []}
                                        setValue={setValue} registerForm={register} errors={errors}>
                                        {(services || []).map((service) => (
                                            <Option key={service.id}
                                                text={service.name} value={service.id.toString()}
                                                className={styles.option}>
                                                {service.name}
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MCTextAria label="Description" fieldName="description" placeholder="Enter description here" className="form-control" id="description"
                                        registerForm={register} errors={errors} />
                                </Col>
                            </Row>

                        </>
                    </Form>
                    {/* <DevTool control={control} /> */}
                </CardBody>
            </Card>
        </div >
    );
}

export const action = async ({ request, params }: any) => {
    const formData = await request.formData();
    const data: InvoiceInput = Object.fromEntries(formData) as InvoiceInput;
    //const data = fromEntries(formData);

    const newInvoice: InvoiceInput = {
        date: dayjs(data.date).toDate(),
        refNo: data.refNo,
        serviceId: Number(data.serviceId),
        unitId: Number(data.unitId),
        currencyId: Number(data.currencyId),
        totalPrice: Number(data.totalPrice),
        description: data.description,
        statusId: 0,
        invNo: ""
    };

    let invoiceId: number = Number(params.invoiceId);
    if (request.method === "POST") {
        const id = await add(newInvoice);
        invoiceId = id;
    } else if (request.method === "PUT") {
        await update(invoiceId, newInvoice as InvoiceInput);
    }
    return redirect(`/invoices/${invoiceId}`);

}

export const loader = async ({ params }: any) => {
    if (params.invoiceId && Number(params.invoiceId)) {
        const event = await get(Number(params.invoiceId))
        return event;
    }

    return null;
}


export default InvoiceDetail