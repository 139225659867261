import * as Yup from "yup";

export const userSchema = Yup.object()
    .shape({
        code: Yup.string().default(""),
        loginName: Yup.string().default("").required("Please enter login name"),
        firstName: Yup.string().default("").required("Please enter first name"),
        lastName: Yup.string().default("").required("Please enter lastfirst name"),
        phoneNo: Yup.string().default("").required("Please enter phone number"),
        email: Yup.string().email("Invalid email format").default("").required("Please enter phone number"),
        units: Yup.array().of(Yup.number().integer()).default([])

    });

export type UserInput = Yup.InferType<typeof userSchema>;

export interface IUserList {
    id: string,
    loginName: string,
    firstName: string,
    lastName: string,
    code: string,
    phoneNo: string,
    email: string,
    unitId: Number,
    unitNumber: string,
    unitName: string,
    zone: string,
    building: string
}