import React from 'react'
import { StatusColor } from '../../../constants/status'

interface StatusIconProps {
    statusId: number
    statusName: string
}

export const   StatusIcon = ({ statusId, statusName }: StatusIconProps) =>{
    return (
        <i className={StatusColor[statusId].className}>{statusName}</i>
    )
}
