
import { IPagination, IServiceBasicList, IApiResponse } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "services";
const LIST_BASIC_URL = BASE_RESOURCE + "/list-basic";

const api = new appApi();

export const listBasic = (): Promise<IApiResponse<IServiceBasicList[]>> => {
    return api.list<IServiceBasicList>(LIST_BASIC_URL, {} as IPagination, { "lid": 1 })
}