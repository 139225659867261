interface MenuItemsProps {
    id: number;
    novidade?: any;
    label: string;
    icon?: string;
    link?: string;
    primaryLink?: string;
    badge?: string;
    badgecolor?: string;
    subItems?: any;
    isHeader?: boolean

}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Main",
        isHeader: true
    },
    {
        id: 2,
        label: "Events",
        icon: "speaker",
        link: "/#",
        novidade: false,
        subItems: [
            {
                id: 22,
                label: "Events",
                icon: "file-text",
                link: "/events",
                primaryLink: "/events",
                parentId: 3
            },
            {
                id: 23,
                label: "Events New",
                icon: "plus-circle",
                link: "/events/new",

                parentId: 2
            }
            
        ]
    },
    
    {
        id: 3,
        label: "Transactions",
        isHeader: true
    },

    {
        id: 4,
        label: "Invoices",
        icon: "file",
        link: "/#",
        subItems: [
            {
                id: 41,
                label: "Invoices",
                icon: "file-text",
                link: "/invoices",
                parentId: 4
            },
            {
                id: 42,
                label: "Invoice New",
                icon: "plus-circle",
                link: "/invoices/new",
                parentId: 4
            },
        ]
    },

    {
        id: 5,
        label: "Payments",
        icon: "tag",
        link: "/#",
        subItems: [
            {
                id: 51,
                label: "Payments",
                icon: "file-text",
                link: "/payments",
                parentId: 5
            },
        ]
    },

    {
        id: 6,
        label: "Request",
        icon: "file",
        link: "/#",
        subItems: [
            {
                id: 61,
                label: "Requests",
                icon: "file-text",
                link: "/requests",
                parentId: 6
            },
            // {
            //     id: 62,
            //     label: "Re New",
            //     icon: "plus-circle",
            //     link: "/invoices/new",
            //     parentId: 4
            // },
        ]
    },

    {
        id: 20,
        label: "Accounts & Units",
        isHeader: true
    },
    
    {
        id: 21,
        label: "Units",
        icon: "book",
        link: "/#",
        subItems: [
            {
                id: 211,
                label: "Units",
                icon: "file-text",
                link: "/units",
                parentId: 21
            },
            {
                id: 212,
                label: "Unit New",
                icon: "plus-circle",
                link: "/units/new",
                parentId: 21
            },
        ]
    },
    
    {
        id: 6,
        label: "Admin & Users",
        isHeader: true
    },
    
    {
        id: 7,
        label: "Users",
        icon: "book",
        link: "/#",
        subItems: [
            {
                id: 71,
                label: "Users",
                icon: "file-text",
                link: "/users",
                parentId: 7
            },
            {
                id: 72,
                label: "User New",
                icon: "plus-circle",
                link: "/users/new",
                parentId: 7
            },
        ]
    },
    {
        id: 8,
        label: "Admin Users",
        icon: "user",
        link: "/#"
    },

    

];

export { menuItems };
