import React, { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";

//import components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loader from "../CommonForBoth/Loader";

//constants
import {
  layoutTypes
} from "../../constants/layout";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  changelayoutMode,
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

interface LayoutProps {
  children?: any;
}

const Layout = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      layoutMode: layout.layoutMode,
      leftSideBarType: layout.leftSideBarType,
      layoutWidth: layout.layoutWidth,
      topbarTheme: layout.topbarTheme,
      layoutType: layout.layoutType,
      leftSideBarTheme: layout.leftSideBarTheme,
      isPreloader: layout.isPreloader,
    })
  );

  // Inside your component
  const {
    layoutMode,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    layoutType,
    leftSideBarTheme,
    isPreloader,
  } = useSelector(selectLayoutProperties);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout(layoutTypes.VERTICAL));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutMode) {
      dispatch(changelayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {isLoading && <Loader isCenter={false} />}
          {!isLoading && <Outlet />}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;