
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { FieldValues, Path } from "react-hook-form";
import { Input, Field } from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";


interface MCInputProps<T extends FieldValues> extends MCBaseInputProp<T>, InputProps {
}

export const MCText = <T extends FieldValues>({ label, fieldName, registerForm, registerProps, errors, ...props }: MCInputProps<T>) => {
    const name = fieldName ?? "";
    const { ref, ...registerInput } = registerForm(name as Path<T>, { ...registerProps });
    const errorMessage = errors[fieldName]?.message;
    
    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>

                <Input ref={ref} {...props} {...registerInput} {...(errors ? { invalid: true } : {})} autoComplete="off"/>
            </Field>
            
        </FormGroup>
    )
}
