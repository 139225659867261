
import { IPagination, IUserList, UserInput, IUserPostResponse, IApiResponse } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "users";
const LIST_URL = BASE_RESOURCE + "/list";
const GET_URL = BASE_RESOURCE + "/{userCode}/get";
const ADD_URL = BASE_RESOURCE + "/add";
const UPDATE_URL = BASE_RESOURCE + "/{userCode}/update";
const DELETE_URL = BASE_RESOURCE + "/{userCode}/delete";


const api = new appApi();

export const list = (pagination: IPagination): Promise<IApiResponse<IUserList[]>> => {
    return api.list<IUserList>(LIST_URL, pagination, { "lid": 1 })
}

export const get = async (userCode: string): Promise<IApiResponse<UserInput>> => {
    const url = GET_URL.replace("{userCode}", userCode.toString());
    return await api.get<UserInput>(url);
}

export const add = async (event: UserInput): Promise<string> => {
    const response = await api.post<UserInput, IUserPostResponse>(ADD_URL, event);
    return response.data.code as string;
};

export const update = async (userCode: string, event: UserInput) => {
    const url = UPDATE_URL.replace("{userCode}", userCode.toString());
    return await api.update<UserInput, {}>(url, event);

};

export const remove = async (userCode: string) => {
    const url = DELETE_URL.replace("{userCode}", userCode.toString());
    return await api.delete(url);
};