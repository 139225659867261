import { Container } from "reactstrap";
import dayjs from 'dayjs';
import { add, update, get } from "../../services/apiEvent";
import { eventSchema, EventInput, IApiResponse } from "../../types/index";

import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { redirect, Form, useActionData, useNavigation, useSubmit, useLoaderData, useParams } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DevTool } from "@hookform/devtools";
import { DataFlow, MCText, MCTextAria, MCDate } from "../../components/CommonForBoth/Entries/index";
import UploadFile from "src/components/Common/UploadFile";


const EventDetail = () => {
    document.title = "Event Detail | Fatoura - Admin";
    const { data: event } = useLoaderData() as IApiResponse<EventInput> || {};
    const { eventId } = useParams();
    const navigation = useNavigation();
    const submit = useSubmit();
    const isSubmitting = navigation.state === "submitting";
    const initValues = eventSchema.cast(event || {});

    const { register, handleSubmit, setValue, formState: { errors }, control } = useForm<EventInput>({
        resolver: yupResolver(eventSchema),
        defaultValues: initValues,
    });

    const onSubmit = (data: any, e: any) => {
        const method = !eventId || Number(eventId) === 0 ? "post" : "put";
        submit(data, { method: method });
    };

    const setImageId = (imageId: string) => {
        setValue("imageId", imageId);
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Events" breadcrumbItem="Event Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={isSubmitting} isEditMode={true} formName={"event-form"} />
                    </CardHeader>
                    <CardBody>
                        <Form id="event-form" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <UploadFile imageUrl={event?.imageUrl || ""} setImageId={setImageId}></UploadFile>
                                </Col>
                            </Row>
                            <Row >
                                <Col xl={10} className="d-grid gap-3">
                                    <Row >
                                        <Col md={6} sm={6}  >

                                            {/* <MCText label="Date" fieldName="date" placeholder="date" className="form-control" id="date"
                                                registerForm={register} errors={errors} registerProps={{ valueAsDate: true }}
                                                type="datetime-local"
                                            /> */}

                                            <MCDate label="Date" fieldName="date" placeholder="Select a date" className="form-control" id="date"
                                                registerForm={register} errors={errors} registerProps={{ valueAsDate: true }} selectedDateValue={initValues.date} setValue={setValue} />

                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            {/* <Label htmlFor="title">Title</Label> */}
                                            <MCText label="Title" fieldName="title" placeholder="enter title here" className="form-control" id="title" type="text"
                                                registerForm={register} errors={errors} />
                                        </Col>
                                        <Col >
                                            {/* <Label htmlFor="titleAr" style={{ textAlign: "right" }} className="d-flex justify-content-end">العنوان</Label> */}
                                            <MCText label="العنوان" fieldName="titleAr" placeholder="أدخل العنوان هنا" className="form-control" id="titleAr" type="text"
                                                registerForm={register} errors={errors} style={{ textAlign: "right" }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>

                                            <MCTextAria fieldName="description" label="Description" placeholder="description" className="form-control" id="description"
                                                registerForm={register} errors={errors}
                                            />
                                        </Col>
                                        <Col>
                                            {/* <Label htmlFor="descriptionAr" className="d-flex justify-content-end">الوصف</Label> */}
                                            <MCTextAria fieldName="descriptionAr" label="الوصف" placeholder="أدخل الوصف هنا" className="form-control d-flex justify-content-end" id="descriptionAr"
                                                registerForm={register} errors={errors}
                                                style={{ textAlign: "right" }}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Form>

                        <DevTool control={control} />
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const action = async ({ request, params }: any) => {
    const formData = await request.formData();
    const data: EventInput = Object.fromEntries(formData) as EventInput;

    const newEvent: EventInput = {
        ...data,
        date: dayjs(data.date).toDate(),
        imageId: data.imageId,
        endDate: { time: new Date(), valid: false },
        contentCategoryId: Number(data.contentCategoryId)
    };

    let eventId: number = Number(params.eventId);
    if (request.method === "POST") {
        const id = await add(newEvent as EventInput);
        eventId = id;
    } else if (request.method === "PUT") {
        await update(eventId, newEvent as EventInput);
    }
    return redirect(`/events/${eventId}`);
}

export const loader = async ({ params }: any) => {
    if (params.eventId) {
        const event = await get(Number(params.eventId))
        return event;
    }

    return null;

}


export default EventDetail;