import App from "./App";
import { Provider } from "react-redux";
import { store } from "./slices";
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang')

root.render(
  
  <FluentProvider theme={webLightTheme} dir={lang==="ar"? "rtl": "ltr"}>
    <Provider store={store}>
      <App />
    </Provider>
  </FluentProvider>,
);
