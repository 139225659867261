import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { get, map } from "lodash";
import { withTranslation } from "react-i18next";
import { SetLocal, SetLocalAsString, LocalKeys, GetLocalAsString } from "../../../services/localStorage";
import { refreshPage } from "../../../helpers/utils";

//i18n
import i18n from "../../../i18n";
import languages from "../../../common/languages";

const LanguageDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState<string>(GetLocalAsString(LocalKeys.GLOBAL_LANGUAGE));
  const [menu, setMenu] = useState<boolean>(false);


  useEffect(() => {
    const currentLanguage: any = GetLocalAsString(LocalKeys.GLOBAL_LANGUAGE)
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string) => {
    //set language as i18n

    setSelectedLang(lang);
    SetLocalAsString(LocalKeys.GLOBAL_LANGUAGE, lang)
    SetLocal(LocalKeys.GLOBAL_LANGUAGE_ID, languages[lang].id)
    i18n.changeLanguage(lang);

    let params = ""
    if (lang !== "en") {
      params = "lang=" + lang;
    }
    refreshPage(params);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="fatoura"
            height="16"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="fatoura"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);