import usFlag from "../assets/images/flags/us.jpg";
import iqFlag from "../assets/images/flags/iq.jpg";
 
const languages: { [key: string]: any } = {
  en: {
    id: 1,
    label: "English",
    flag: usFlag,
  },
  ar: {
    id: 3,
    label: "العربية",
    flag: iqFlag,
  },

};

export default languages;
