
import { IPagination, IInvoiceList, InvoiceInput, IPostResponse, IApiResponse } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "invoices";
const LIST_URL = BASE_RESOURCE + "/list";
const GET_URL = BASE_RESOURCE + "/{id}/get";
const ADD_URL = BASE_RESOURCE + "/add";
const UPDATE_URL = BASE_RESOURCE + "/{id}/update";
const DELETE_URL = BASE_RESOURCE + "/{id}/delete";


const api = new appApi();

export const list = (pagination: IPagination): Promise<IApiResponse<IInvoiceList[]>> => {
    return api.list<IInvoiceList>(LIST_URL, pagination, { "lid": 1 })
}

export const get = async (id: number): Promise<IApiResponse<InvoiceInput>> => {
    const url = GET_URL.replace("{id}", id.toString());
    return await api.get<InvoiceInput>(url);
}

export const add = async (event: InvoiceInput): Promise<number> => {
    const response = await api.post<InvoiceInput, IPostResponse>(ADD_URL, event);
    return response.data.id as number;
};

export const update = async (id: number, event: InvoiceInput) => {
    const url = UPDATE_URL.replace("{id}", id.toString());
    await api.update<InvoiceInput, {}>(url, event);
    return;
};

export const remove = async (id: number) => {
    const url = DELETE_URL.replace("{id}", id.toString());
    await api.delete(url);
    return;
};