import { useMemo } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { list as listUser } from "../../services/apiUser";

import { IApiResponse, IPagination, IUserList } from "../../types/index";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";
import { Link } from "react-router-dom";

const UserList = () => {
    document.title = "Users | Fatoura - Admin";
    const navigate = useNavigate();
    const { data: list } = useLoaderData() as IApiResponse<IUserList[]>;

    const columns = useMemo<ColumnDef<IUserList>[]>(
        () => [
            {
                header: () => <span>Code</span>,
                accessorKey: "code",
                id: 'code',
                //cell: props => props.getValue(),
                cell: ({ cell }: any) => <Link to={`/users/${cell?.row?.original.code}`} className="medium">{`${cell.getValue()}`}</Link>,
                filterFn: "includesString",
                enableSorting: false,
                size: 70
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Login Name</span>,
                accessorKey: "loginName",
                id: 'loginName',
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 100
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Name</span>,
                accessorKey: "firstName",
                id: 'firstName',
                //cell: props => props.getValue(),
                cell: ({ cell }: any) => <span>{cell?.row?.original.firstName + " " + cell?.row?.original.lastName }</span>,
                filterFn: "includesString",
                enableSorting: false,
                size: 100
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Phone #</span>,
                accessorKey: "phoneNo",
                id: 'phoneNo',
                cell: info => info.getValue(),
                //footer: props => props.column.id,
                size: 70
            },
            {
                header: () => <span>Email</span>,
                accessorKey: "email",
                id: 'email',
                cell: info => info.getValue(),
                //footer: props => props.column.id,
                size: 100
            },
            {
                header: () => <span>Unit Number</span>,
                accessorKey: "unitNumber",
                id: 'unitNumber',
                cell: info => info.getValue(),
                size: 100
            },
            {
                header: () => <span>Zone</span>,
                accessorKey: "zone",
                id: 'zone',
                cell: info => info.getValue(),
                size: 100
            },
            {
                header: () => <span>Building</span>,
                accessorKey: "building",
                id: 'building',
                cell: info => info.getValue(),
                size: 100
            },

            
        ],
        [])

    const handleAddNewClick = () => {
        navigate('/users/new');
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Users" breadcrumbItem="List" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {<TableContainer
                                    columns={columns}
                                    data={list}
                                    isGlobalFilter={true}
                                    isAddNewList={true}
                                    addNewText={"Add new user"}
                                    handleAddNewClick={handleAddNewClick}
                                    customPageSize={1}
                                    className="custom-header-css"
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export const loader = async () => {

    const pagination: IPagination = {
        pageNo: 0,
        pageSize: 10
    }

    const list = await listUser(pagination);

    if (!list || list.data === null) {
        const empty: IApiResponse<IUserList[]> = {} as IApiResponse<IUserList[]>;
        empty.data = [];
        return empty;
    }

    return list;
}

export default UserList;
