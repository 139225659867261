import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"


export const userForgetPassword: any = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;

        const data = await response;

        if (data) {
            dispatch(userForgetPasswordSuccess(
                "Reset link are sended to your mailbox, check there first"
            ))
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError))
    }
}