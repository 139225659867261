import { AxiosRequestConfig } from "axios";
import { IPagination, IApiRequest, IApiResponse } from "../../types/index";
import "./apiConfig";
import { axiosClient } from "./apiConfig";
import { GetLocal, LocalKeys } from "../localStorage";

class appApi {
    /**
     * Fetches data from given url
     */
    get = <TResult>(url: string, params?: {}): Promise<IApiResponse<TResult>> => {
        if (params !== undefined) {
            //wrap params within params object
            params = {
                params: params
            }
        }
        return axiosClient.get(url, params);
    };

    /**
     * post given data to url
     */
    post = <TData, TResult>(url: string, data: TData, sessionId?: string): Promise<IApiResponse<TResult>> => {
        const payload: IApiRequest<TData> = {
            Data: data
        };
        if (sessionId) {
            axiosClient.defaults.headers.common["X-Request-Id"] = sessionId;
        }
        return axiosClient.post(url, payload);
    };

    /**
     * Updates data
     */
    update = <TData, TResult>(url: string, data: TData, sessionId?: string): Promise<IApiResponse<TResult>> => {
        const payload: IApiRequest<TData> = {
            Data: data
        };

        if (sessionId) {
            axiosClient.defaults.headers.common["X-Request-Id"] = sessionId;
        }
        return axiosClient.put(url, payload);
    };

    /**
     * Delete 
     */
    delete = (url: string, data?: {}, sessionId?: string) => {
        if (sessionId) {
            axiosClient.defaults.headers.common["X-Request-Id"] = sessionId;
        }
        return axiosClient.delete(url, { ...data });
    };

    list = <TData>(url: string, pagination: IPagination, params?: any): Promise<IApiResponse<TData[]>> => {

        if (!params) {
            params = {}
        }

        const lid = GetLocal<number>(LocalKeys.GLOBAL_LANGUAGE_ID);
        params.lid = lid;

        //wrap params within params object
        params = {
            params: params
        }
        return axiosClient.get(url, params);
    };

    upload = <TResult>(url: string, fileInput: any): Promise<IApiResponse<TResult>> => {
        const file = new FormData();
        file.append("file", fileInput, fileInput.name);
        const headerConfig: AxiosRequestConfig<FormData> = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "*/*"
            }
        }

        return axiosClient.post(url, file, headerConfig);
    };

};


export { appApi };