import axios, { AxiosResponse } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import camelcaseKeys from 'camelcase-keys'
//import TokenService from "../tokenService";
import { IApiResponse } from "../../types/apiTypes";
import { LocalKeys, SetLocalAsString, RemoveLocal } from "../localStorage";

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL + "/api/admin/v1/";
const APP_ID = process.env.REACT_APP_APP_ID;

const options = {
    ignoreParams: true,
    caseMiddleware: {
        requestTransformer: (config: any) => {

            return config;
        },
        requestInterceptor: (config: any) => {
            config.data = camelcaseKeys(config.data, { deep: true, pascalCase: true })
            return config;
        }
    }
};

const axiosApi = applyCaseMiddleware(axios.create(), options);
axiosApi.defaults.headers.common["X-App-Id"] = APP_ID;
axiosApi.defaults.baseURL = API_URL;
axiosApi.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axiosApi.interceptors.response.use(function (response) {
    const result = response.data ? response.data : response;
    if (result?.data?.token) {
        const jwtToken = result?.data?.token;
        SetLocalAsString(LocalKeys.TOKEN_KEY, jwtToken);
    }
    return response;
}, function (error) {

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    RemoveLocal(LocalKeys.TOKEN_KEY)
    switch (error.response.status) {
        case 500: message = "Internal Server Error"; break;
        case 401: message = "Invalid credentials"; break;
        case 404: message = "Sorry! the data you are looking for could not be found"; break;
        default: message = error.message || error;
    }
    return Promise.reject(message);
});


class authApi {
    login = <T>(url: string, data: {}): Promise<AxiosResponse<IApiResponse<T>>> => {
        RemoveLocal(LocalKeys.TOKEN_KEY);
        return axiosApi.post<IApiResponse<T>>(url, data);
    };

    post = (url: string, data: {}) => {
        return axiosApi.post(url, data);
    };

    logout = () => {
        RemoveLocal(LocalKeys.TOKEN_KEY);
    }

}

export { authApi };