import { useMemo } from "react";
import dayjs from 'dayjs';
import { useLoaderData, Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { list as listRequest } from "../../services/apiRequest";

import { IApiResponse, IPagination, IRequestList } from "../../types/index";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";
import { StatusIcon } from "../../components/CommonForBoth/Entries/index";

const Events = () => {
    document.title = "Requests | Fatoura - Admin";
    const { data: list } = useLoaderData() as IApiResponse<IRequestList[]>;
    const columns = useMemo<ColumnDef<IRequestList>[]>(
        () => [
            {
                accessorKey: "invNo",
                id: 'invNo',
                //cell: info => info.getValue(),
                cell: ({ cell }: any) => <Link to={`/requests/${cell?.row?.original.id}`} className="medium">{`${cell.getValue()}`}</Link>,
                header: () => <span>InvNo</span>,
                footer: props => props.column.id,
                size: 100
            },

            {
                header: () => <span>Date</span>,
                accessorKey: "date",
                id: 'Date',
                cell: props => dayjs(props.getValue() as Date).format("DD/MM/YYYY"),
                size: 50
                //footer: props => props.column.id,
            },
            {
                accessorKey: "service",
                id: 'service',
                cell: info => info.getValue(),
                header: () => <span>Service</span>,
                footer: props => props.column.id,
                size: 100
            },
            {
                header: () => <span>Unit</span>,
                accessorKey: "unitFullNumber",
                id: 'unitFullNumber',
                //cell: props => props.getValue(),
                cell: ({ cell }: any) => <Link to={`/units/${cell?.row?.original.unitId}`} className="medium">{`${cell.getValue()}`}</Link>,
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Status</span>,
                accessorKey: "status",
                id: 'status',
                cell: ({ cell }: any) => <StatusIcon statusId={cell?.row?.original.statusId} statusName={cell.getValue()} />,
                //icon: "mdi mdi-check-circle-outline text-success",
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },

            {
                header: () => <span>Description</span>,
                accessorKey: "description",
                id: 'description',
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 100
                //footer: props => props.column.id,
            },
        ],
        [])
 
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Requests" breadcrumbItem="List" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {<TableContainer
                                    columns={columns}
                                    data={list}
                                    isGlobalFilter={true}
                                    isAddNewList={false}
                                    customPageSize={1}
                                    className="custom-header-css"
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export const loader = async () => {

    const pagination: IPagination = {
        pageNo: 0,
        pageSize: 10
    }

    const list = await listRequest(pagination);
    
    if (!list || list.data === null) {
        const empty: IApiResponse<IRequestList[]> = {} as IApiResponse<IRequestList[]>;
        empty.data= [];
        return empty;
      }
    
      return list;
}

export default Events;
