import { MouseEvent, useState } from 'react'
import { Container } from "reactstrap";

import { InvoiceInput, IApiResponse } from 'src/types';
import { CurrencyTypeObject } from "../../constants";

import { get, remove } from "../../services/apiInvoice";

import { DataFlow, MCLabel, NumberFormatCol } from "../../components/CommonForBoth/Entries/index";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate, useParams, useLoaderData, useSubmit, redirect } from "react-router-dom";
import DeleteModal from "../../components/CommonForBoth/DeleteModal";
import useInvoiceData from "./hook";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const InvoiceView = () => {
    document.title = "Invoice Detail | Fatoura - Admin";
    const { invoiceId } = useParams();
    const { data: invoice } = useLoaderData() as IApiResponse<InvoiceInput> || {};
    const submit = useSubmit();
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const { units, services } = useInvoiceData();

    const unitName = units?.find(x => x.id === invoice.unitId)?.name;
    const serviceName = services?.find(x => x.id === invoice.serviceId)?.name;

    //commands
    const onEdit = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        navigate(`/invoices/${invoiceId}/edit`);
    }

    const onHandleDelete = () => {
        setRemoveModal(false);
        submit(null, { method: "delete" });
    }

    const onDelete = (invoice: MouseEvent<HTMLButtonElement>) => {
        invoice.preventDefault();
        setRemoveModal((value) => !value);
    }

    return (
        <div className="page-content">
            <DeleteModal showModal={removeModal} setModal={setRemoveModal} onHandleDelete={onHandleDelete} />
            <Container fluid>
                <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} onEdit={onEdit} onDelete={onDelete} isEditMode={false} formName={"invoice-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row >
                            <Col>
                                <MCLabel label='Inv#' value={invoice.invNo} />
                            </Col>
                            <Col>
                                <MCLabel label='Ref #' value={invoice.refNo} />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Date' value={invoice.date} />
                            </Col>
                            <Col>
                                <MCLabel label='Unit' value={unitName} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Currency' value={CurrencyTypeObject[invoice.currencyId].symbol} />
                            </Col>
                            <Col>
                                <NumberFormatCol currencyId={invoice.currencyId} value={invoice.totalPrice} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Service' value={serviceName} />
                            </Col>

                        </Row>
                        <Row >
                            <Col>
                                <MCLabel label='Description' value={invoice.description} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export const loader = async ({ params }: any) => {
    if (params.invoiceId) {
        const event = await get(Number(params.invoiceId))
        return event;
    }

    return null;
}

export const action = async ({ request, params }: any) => {
    let invoiceId: number = Number(params.invoiceId);
    await remove(invoiceId);
    return redirect(`/invoices`);
}

export default InvoiceView