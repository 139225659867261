import { useMemo } from "react";
import dayjs from 'dayjs';
import { Link, useLoaderData } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { useNavigate } from "react-router-dom";
import { list as listEvent } from "../../services/apiEvent";
import { IEventList, IApiResponse, IPagination } from "../../types/index";
import { getImageFullPath } from "../../helpers/utils";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";

const Events = () => {
  document.title = "Events | Fatoura - Admin";
  const { data: list } = useLoaderData() as IApiResponse<IEventList[]>;
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<IEventList>[]>(
    () => [
      {
        accessorKey: "imageUrl",
        id: 'image',
        cell: ({ cell }: any) => (
          <div className="flex-shrink-0" >
            <img src={getImageFullPath(cell.getValue())} className="img-fluid" alt="" />
          </div >
        ),
        header: () => <span>Title</span>,
        size: 150
      },

      {
        accessorKey: "title",
        id: 'title',
        cell: ({ cell }: any) => <Link to={`/events/${cell?.row?.original.id}`} className="medium">{`${cell.getValue()}`}</Link>,
        header: () => <span>Title</span>,
        size: 150
      },
      {
        accessorKey: "description",
        id: 'description',
        cell: info => info.getValue(),
        header: () => <span>Description</span>,
        footer: props => props.column.id,
        size: 200
      },
      {
        header: () => <span>Date</span>,
        accessorKey: "date",
        id: 'Date',
        cell: props => dayjs(props.getValue() as Date).format("DD/MM/YYYY"),
        size: 50
        //footer: props => props.column.id,
      },
      {
        header: () => <span>Content Type</span>,
        accessorKey: "contentCategory",
        id: 'category',
        cell: props => props.getValue(),
        filterFn: "includesString",
        enableSorting: false,
        size: 30
        //footer: props => props.column.id,
      },
    ],
    [])

  const handleAddNewClick = () => {
    navigate('/events/new');
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Events" breadcrumbItem="List" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={list}
                  isGlobalFilter={true}
                  isAddNewList={true}
                  addNewText={"Add new event"}
                  handleAddNewClick={handleAddNewClick}
                  customPageSize={1}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


export const loader = async () => {

  const pagination: IPagination = {
    pageNo: 0,
    pageSize: 10
  }

  const list = await listEvent(pagination);
  if (!list || list.data === null) {
    const empty: IApiResponse<IEventList[]> = {} as IApiResponse<IEventList[]>;
    empty.data= [];
    return empty;
  }

  return list;
}

export default Events;
