
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { FieldValues, UseFormSetValue, Path, PathValue, Controller } from "react-hook-form";
import { Input, Field } from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";


interface MCInputProps<T extends FieldValues> extends MCBaseInputProp<T>, InputProps {
    selectedDateValue?: Date,
    setValue: UseFormSetValue<T>,
}

export const MCDate = <T extends FieldValues>({ label, fieldName, selectedDateValue, registerForm, registerProps, setValue, errors, ...props }: MCInputProps<T>) => {
    const name = fieldName ?? "";

    const { ref, ...registerInput } = registerForm(name as Path<T>, { ...registerProps });
    const handleChange = (e: any, v: any) => {
        setValue(fieldName as Path<T>, v.value as PathValue<T, any>, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    };

    const errorMessage = errors[fieldName]?.message;
    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>
                <Input {...props} ref={ref} value={selectedDateValue?.toISOString().slice(0, 16)}  {...registerInput} type="datetime-local" onChange={handleChange} />

            </Field>
        </FormGroup>
    )
}
