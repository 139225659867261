import { registerUserSuccessful, registerUserFailed } from "./reducer";

import { SignUp } from "../../../services/apiAuthService";


export const registerUser: any = (user: any) => async (dispatch: any) => {
    try {
        const response = SignUp(user);
        const data = await response;
        if (data) {
            dispatch(registerUserSuccessful(data));
        }

    } catch (error) {
        dispatch(registerUserFailed(error))
    }
};