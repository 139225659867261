import * as Yup from "yup";
import { IItemList } from "./lookup";

export const unitSchema = Yup.object()
    .shape({
        name: Yup.string().default("").required("Please Enter Name"),
        nameAr: Yup.string().default("").required("Please Enter Name in arabic"),
        unitNumber: Yup.string().default("").required("Please Enter Unit Number"),
        zone: Yup.string().default("").required("Please Select Zone"),
        building: Yup.string().default("").required("Please Select Building"),
    });

export type UnitInput = Yup.InferType<typeof unitSchema>;


////////
export interface IUnitBasicList extends IItemList {
    unitNumber: string
    zone: string,
    building: string,
}


export interface IUnitList {
    id: number,
    name: string,
    building: string,
    zone: string,
    unitNumber: string,
    unitFullNumber: string,
}

export interface IUnitResidentList {
    id: number,
    name: string,
    building: string,
    zone: string,
    unitNumber: string,
    userId: string,
    loginName: string,
    fullName: string
}