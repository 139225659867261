
import { appApi } from "./api/api";

const BASE_RESOURCE = "images";
//const GET_URL = BASE_RESOURCE+"/{id}/get";
const UPLOAD_URL = BASE_RESOURCE+"/upload";
//const DELETE_URL = BASE_RESOURCE+"/{id}/delete";


const api = new appApi();
interface IFileResponse {
    imageId: string
}

export const upload = async (file: any): Promise<string> => {
    const response = await api.upload<IFileResponse>(UPLOAD_URL, file);
    return response.data.imageId;
};

// export const get = async (id: number): Promise<IApiResponse<InvoiceInput>> => {
//     const url = GET_URL.replace("{id}", id.toString());
//     return await api.get<InvoiceInput>(url);
// }

// export const remove = async (id: number) => {
//     const url = DELETE_URL.replace("{id}", id.toString());
//     await api.delete(url);
//     return;
// };