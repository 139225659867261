import { redirect, Form, useNavigation, useSubmit, useLoaderData, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Card, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";
import { Input, Field } from '@fluentui/react-components';

import { userSchema, UserInput, IApiResponse } from "../../types/index";
import { add, update, get } from "../../services/apiUser";

//import { DevTool } from "@hookform/devtools";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataFlow, MCText } from "../../components/CommonForBoth/Entries/index";
import { MCInputUserUnit } from "src/components/CommonForBoth/Entries/MCInputUserUnit";


const UserDetail = () => {
    document.title = "Event Detail | Fatoura - Admin";
    const { userCode } = useParams();
    const navigation = useNavigation();
    const submit = useSubmit();

    const isSubmitting = navigation.state === "submitting";
    const { data: user } = useLoaderData() as IApiResponse<UserInput> || {};
    const initValues = userSchema.cast(user || {});
    const { control, register, getValues, handleSubmit, setValue, formState: { errors } } = useForm<UserInput>({
        resolver: yupResolver(userSchema),
        defaultValues: initValues,
    });

    ///////// methods

    //commands
    const onSubmit = (data: any, e: any) => {
        const method = !userCode || userCode === "" ? "post" : "put";
        submit(data, { method: method });
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Users" breadcrumbItem="User Details" />
            </Container>
            <Card>
                <CardHeader>
                    <DataFlow isSubmitting={isSubmitting} isEditMode={true} formName={"user-form"} />
                </CardHeader>
                <CardBody>
                    <Form id="user-form" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <input name="honeypot" className="visually-hidden" autoComplete="off"></input>
                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCText label="Login Name" fieldName="loginName" placeholder="enter login name here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Field label="User Code:">
                                            <Input name="userCode" placeholder="-" className="form-control" id="userCode"
                                                type="text" value={user ? user.code : ""} disabled={true} />
                                        </Field>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCText label="First Name" fieldName="firstName" placeholder="enter first name here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>
                                <Col>
                                    <MCText label="Last Name" fieldName="lastName" placeholder="enter last name here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>

                            </Row>

                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCText label="Email" fieldName="email" placeholder="enter email here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>
                                <Col>
                                    <MCText label="PhoneNo" fieldName="phoneNo" placeholder="enter phone number here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} />
                                </Col>
                            </Row>

                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCInputUserUnit fieldName="units" registerForm={register} errors={errors} control={control} setValue={setValue} getValues={getValues} />
                                </Col>

                            </Row>

                        </>
                    </Form>
                    {/* <DevTool control={control} /> */}
                </CardBody>
            </Card>
        </div >
    );
}

export const action = async ({ request, params }: any) => {
    const formData = await request.formData();
    const data: UserInput = Object.fromEntries(formData) as UserInput;
    const unitInt = typeof data.units === 'string' ? (data.units as string).split(",").map((x: any) => parseInt(x.trim(), 10)) : data.units;
    const newInvoice: UserInput = {
        ...data,
        units: unitInt
    };

    let userCode: string = params.userCode;
    if (request.method === "POST") {
        const code = await add(newInvoice);
        userCode = code;
    } else if (request.method === "PUT") {
        await update(userCode, newInvoice as UserInput);
    }
    return redirect(`/users/${userCode}`);
}

export const loader = async ({ params }: any) => {
    if (params.userCode) {
        const event = await get(params.userCode)
        return event;
    }

    return null;
}


export default UserDetail