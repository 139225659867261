import { useState } from 'react';
import {  useRouteError } from 'react-router-dom'
import {  Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const Error = () => {
    const [show, setModal] = useState(true);
    const error: any = useRouteError();

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_center() {
        removeBodyCss();
        setModal(false);
    }

    //return <div>Dang!</div>;
    return (
        <Modal isOpen={show} toggle={() => { tog_center(); }} centered={true}>
            <ModalHeader>
                <p className="text-muted mx-1 mb-0">Fatoura</p>
                <button
                    type="button"
                    onClick={() => { setModal(false); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true"></span>

                </button>
            </ModalHeader>
            <ModalBody>
                <div className="text-center">

                    <div className="mt-4">
                        <i className="text-danger x-circle"></i>
                        <p className="text-muted mx-4 mb-0">{error?.message? error?.message: (error?error: "nudefined error")}</p>
                    </div>

                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <Button type="button" color="light" className="btn w-sm" onClick={tog_center}>Close</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Error;