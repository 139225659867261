
import { FormGroup } from 'reactstrap';
import { MCBaseInputProp } from "./index";
import { Controller, FieldValues, Path } from "react-hook-form";
import { Input, Field } from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";


interface MCInputProps<T extends FieldValues> extends MCBaseInputProp<T>, InputProps {
    control?: any,
}

export const MCText2 = <T extends FieldValues>({ label, fieldName, registerForm, control, registerProps, errors, ...props }: MCInputProps<T>) => {
    const errorMessage = errors[fieldName]?.message;

    return (
        <FormGroup>
            <Field
                label={label}
                validationState={errorMessage ? "error" : "none"}
                validationMessage={<>{errorMessage}</>}>
                <Controller
                    control={control}
                    name={fieldName}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input {...props} ref={ref}  onChange={onChange} value={value} {...(errors ? { invalid: true } : {})} />
                    )}
                />
                {/* <Input ref={ref} {...props} {...registerInput} {...(errors ? { invalid: true } : {})} /> */}
            </Field>

        </FormGroup>
    )
}
