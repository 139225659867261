import { Navigate } from "react-router-dom";
import Error from '../components/CommonForBoth/Error';

import type { RouteObject } from 'react-router-dom';

//Events
import Events, { loader as eventLoader } from "../pages/Events/EventList";
import EventDetail, { action as eventAction, loader as eventDetailLoader } from "../pages/Events/EventDetail"
import EventView, { loader as eventViewLoader, action as viewEventAction } from "../pages/Events/EventView"

//invoices
import Invoices, { loader as invoiceLoader } from "../pages/Invoice/InvoiceList";
import InvoiceDetail, { action as invoiceAction, loader as invoiceDetailLoader } from "../pages/Invoice/InvoiceDetail";
import InvoiceView, { loader as invoiceViewLoader, action as viewInvoiceAction } from "../pages/Invoice/InvoiceView";

//requests
import Requests, { loader as requestLoader } from "../pages/Request/RequestList";
import RequestDetail, { action as requestAction, loader as requestDetailLoader } from "../pages/Request/RequestDetail";
import RequestView, { loader as requestViewLoader, action as viewRequestAction } from "../pages/Request/RequestView";

//payments
import Payments, { loader as paymentLoader } from "../pages/Payment/PaymentList";
import PaymentView, { loader as paymentViewLoader } from "../pages/Payment/PaymentView"

//users
import Users, { loader as userLoader } from "../pages/User/UserList";
import UserDetail, { action as userAction, loader as userDetailLoader } from "../pages/User/userDetail";
import UserView, { loader as userViewLoader, action as viewUserAction } from "../pages/User/UserView";

//units
import Units, { loader as unitLoader } from "../pages/Unit/UnitList";
import UnitDetail, { action as unitAction, loader as unitDetailLoader } from "../pages/Unit/UnitDetail";
import UnitView, { loader as unitViewLoader, action as viewUnitAction } from "../pages/Unit/UnitView";


//profile
import UserProfile from "../pages/Authentication/user-profile"

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword"


const userRoutes: RouteObject[] = [
  {
    path: "/#",
    element: <Events />,
    caseSensitive: true,
    errorElement: <Error />,

  },
  //events
  {
    path: "/events",
    element: <Events />,
    caseSensitive: true,
    loader: eventLoader,
    errorElement: <Error />
  },

  {
    path: "/events/new",
    element: <EventDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    action: eventAction
  },
  {
    path: "/events/:eventId/edit",
    element: <EventDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: eventDetailLoader,
    action: eventAction
  },

  {
    path: "/events/:eventId",
    element: <EventView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: eventViewLoader,
    action: viewEventAction
  },

  //invoices
  {
    path: "/invoices",
    element: <Invoices />,
    caseSensitive: true,
    loader: invoiceLoader,
    errorElement: <Error />
  },

  {
    path: "/invoices/new",
    element: <InvoiceDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    action: invoiceAction
  },
  {
    path: "/invoices/:invoiceId/edit",
    element: <InvoiceDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: invoiceDetailLoader,
    action: invoiceAction
  },

  {
    path: "/invoices/:invoiceId",
    element: <InvoiceView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: invoiceViewLoader,
    action: viewInvoiceAction
  },
  /////////

  {
    path: "/payments",
    element: <Payments />,
    caseSensitive: true,
    loader: paymentLoader,
    errorElement: <Error />
  },
  {
    path: "/payments/:paymentId",
    element: <PaymentView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: paymentViewLoader,
  },

  //requests
  {
    path: "/requests",
    element: <Requests />,
    caseSensitive: true,
    loader: requestLoader,
    errorElement: <Error />
  },

  {
    path: "/requests/:requestId/edit",
    element: <RequestDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: requestDetailLoader,
    action: requestAction
  },

  {
    path: "/requests/:requestId",
    element: <RequestView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: requestViewLoader,
    action: viewRequestAction
  },
  /////////

  //Users
  {
    path: "/users",
    element: <Users />,
    caseSensitive: true,
    loader: userLoader,
    errorElement: <Error />
  },

  {
    path: "/users/new",
    element: <UserDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    action: userAction
  },
  {
    path: "/users/:userCode/edit",
    element: <UserDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: userDetailLoader,
    action: userAction
  },

  {
    path: "/users/:userCode",
    element: <UserView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: userViewLoader,
    action: viewUserAction
  },

  ///UNits

  //Users
  {
    path: "/units",
    element: <Units />,
    caseSensitive: true,
    loader: unitLoader,
    errorElement: <Error />
  },

  {
    path: "/units/new",
    element: <UnitDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    action: unitAction
  },
  {
    path: "/units/:unitId/edit",
    element: <UnitDetail />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: unitDetailLoader,
    action: unitAction
  },

  {
    path: "/units/:unitId",
    element: <UnitView />,
    caseSensitive: true,
    errorElement: <Error />,
    loader: unitViewLoader,
    action: viewUnitAction
  },

  /////////

  //
  // //profile
  { path: "/profile", element: <UserProfile /> },
  { path: "*", element: <Navigate to="/events" /> },
];


const authRoutes = [
  //Authentication pages
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
  { path: "/Register", element: <Register /> },
  { path: "/forgot-password", element: <ForgetPwd /> },

];



export { userRoutes, authRoutes };
