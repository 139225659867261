
import { IPagination, IRequestList, RequestInput, IPostResponse, IApiResponse } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "requests";
const LIST_URL = BASE_RESOURCE+"/list";
const GET_URL = BASE_RESOURCE+"/{id}/get";
const ADD_URL = BASE_RESOURCE+"/add";
const UPDATE_URL = BASE_RESOURCE+"/{id}/update";
const DELETE_URL = BASE_RESOURCE+"/{id}/delete";


const api = new appApi();
 
export const list =  (pagination: IPagination): Promise<IApiResponse<IRequestList[]>> => {
    return  api.list<IRequestList>(LIST_URL, pagination, { "lid": 1 })
}

export const get = async (id: number): Promise<IApiResponse<RequestInput>> => {
    const url = GET_URL.replace("{id}", id.toString());
    return await api.get<RequestInput>(url);
}

export const add = async (event: RequestInput, sessionId: string): Promise<number> => {
    const response = await api.post<RequestInput, IPostResponse>(ADD_URL, event);
    return response.data.id as number;
};

export const update = async (id: number, event: RequestInput, sessionId?: string) => {
    const url = UPDATE_URL.replace("{id}", id.toString());
    await api.update<RequestInput, {}>(url, event, sessionId);
    return;
};

export const remove = async (id: number) => {
    const url = DELETE_URL.replace("{id}", id.toString());
    await api.delete(url);
    return;
};