import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

import Loader from "./components/CommonForBoth/Loader";

// layouts Format
import Layout from "./components/VerticalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { getQueryParams } from "./helpers/utils";
//import "./helpers/firebase";

const lang = getQueryParams("lang");

const RtlTheme = React.lazy(() => import('./components/CommonForBoth/RTL'));
const queryClient = new QueryClient();


const App: React.FC = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Authmiddleware><Layout></Layout></Authmiddleware>,
      children: userRoutes,
    },
    ...authRoutes.map((route, idx) => (
      {
        path: route.path,
        element: <NonAuthLayout>{route.element}</NonAuthLayout>
      }
    ))
  ])

  return (

    <>
      <React.Suspense fallback={<></>}>
        {(lang === "ar") && <RtlTheme />}
      </React.Suspense>

      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} fallbackElement={<Loader isCenter={true} />} />
      </QueryClientProvider>
    </>
  )

}

export default App;


