
import { appApi } from "./api/api";
import { IPagination, IInvoiceList, InvoiceInput, IApiResponse } from "../types/index";

const BASE_RESOURCE = "payments";
const LIST_URL = BASE_RESOURCE+"/list";
const GET_URL = BASE_RESOURCE+"/{id}/get";

const api = new appApi();
 
export const list =  (pagination: IPagination): Promise<IApiResponse<IInvoiceList[]>> => {
    return  api.list<IInvoiceList>(LIST_URL, pagination)
}

export const get = async (id: number): Promise<IApiResponse<InvoiceInput>> => {
    const url = GET_URL.replace("{id}", id.toString());
    return await api.get<InvoiceInput>(url);
}
