import * as Yup from "yup";

export const eventSchema = Yup.object()
    .shape({
        date: Yup.date().default(new Date()).required("Please Enter Date").typeError("Please Enter Date"),
        title: Yup.string().default("").required("Please Enter Title"),
        titleAr: Yup.string().default("").required("please enter title in arabic"),
        description: Yup.string().default("").required("Please Enter Description"),
        descriptionAr: Yup.string().default("").required("please enter description in arabic"),
        imageUrl: Yup.string().default("").optional(),
        imageId: Yup.string().optional(),
        endDate: Yup.object().shape({ time: Yup.date().default(new Date()).required(), valid: Yup.bool() }),
        contentCategoryId: Yup.number().default(15)
        
    });


export type EventInput = Yup.InferType<typeof eventSchema>;


export interface IEventList {
    id: number,
    date: Date,
    title: string,
    description: string,
    imageUrl: string
    contentCategory: string
}