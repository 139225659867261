import axios from "axios";
import { IAccount } from "../../types/index";
import { GetLocal, GetLocalAsString, LocalKeys } from "../localStorage";
import applyCaseMiddleware from 'axios-case-converter';
import camelcaseKeys from 'camelcase-keys'

//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL + "/api/admin/v1/";
const APP_ID = process.env.REACT_APP_APP_ID;

const options = {
    ignoreParams: true,
    caseMiddleware: {
        requestTransformer: (config: any) => {
            return config;
        },
        requestInterceptor: (config: any) => {
            const token = GetLocalAsString(LocalKeys.TOKEN_KEY);
            config.headers.set("Authorization", "Bearer " + token);

            const account = GetLocal<IAccount>(LocalKeys.GLOBAL_ACC_KEY);
            config.headers.set("X-Account-Id", account.id);
            if (config.headers["Content-Type"] === "application/json") {
                config.data = camelcaseKeys(config.data, { deep: true, pascalCase: true })
            }

            return config;
        }
    }
};

export const axiosClient = applyCaseMiddleware(axios.create(), options);

axiosClient.defaults.headers.common["X-App-Id"] = APP_ID;
axiosClient.defaults.baseURL = API_URL;
axiosClient.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axiosClient.interceptors.response.use(function (response) {
    const data = response.data ? response.data : response
    return data;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    let status = error?.response?.status;
    if (Number(status) === 401) {
        window.location.href = "/login";
        return Promise.reject(message);
    }

    switch (status) {
        case 401: message = "Your session has expired"; break;

        case 500: message = "Internal Server Error"; break;

        case 404: message = "Sorry! the data you are looking for could not be found"; break;
        default: message = error.message || error;
    }
    return Promise.reject(message);
});

