import * as Yup from "yup";
import { ECurrency } from "../constants/index";

export const invoiceSchema = Yup.object()
    .shape({
        date: Yup.date().default(new Date()).required("Please Enter Date").typeError("Please Enter Date"),
        invNo: Yup.string().default(""),
        refNo: Yup.string().default("").required("Please enter refno"),
        serviceId: Yup.number().default(0).moreThan(0, "Please enter service").required("Please enter unit"),
        unitId: Yup.number().default(0).moreThan(0, "Please enter unit").required("Please enter unit").typeError("Please enter a valid unit"),
        currencyId: Yup.number().default(ECurrency.Dinar).moreThan(0, "Please enter currency").required("Please enter currency"),
        totalPrice: Yup.number().default(0).moreThan(0, "Please enter total price").positive("Total price must be positive").required("Please enter total Price"),
        description: Yup.string().default(""),
        statusId: Yup.number().default(0),
    });

export type InvoiceInput = Yup.InferType<typeof invoiceSchema>;

export interface IInvoiceList {
    id: number,
    date: Date,
    invNo: string,
    service: string,
    
    currencyId: number,
    totalPrice: number,
    statusId: number,
    status: string,
    unitFullNumber: string
}