import { MouseEvent, useState } from "react";
import { Container } from "reactstrap";

import { get, remove } from "../../services/apiEvent";
import { EventInput, IApiResponse } from "../../types/index";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useNavigate, useParams,  useLoaderData, redirect } from "react-router-dom";
import DeleteModal from "../../components/CommonForBoth/DeleteModal";
import { DataFlow, MCLabel } from "../../components/CommonForBoth/Entries/index";

import { getImageFullPath } from "../../helpers/utils";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const EventView = () => {
    document.title = "Event Detail | Fatoura - Admin";
    const { data: event } = useLoaderData() as IApiResponse<EventInput> || {};
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);

    const onEdit = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/events/${eventId}/edit`);
    }

    const onDelete = () => {
        //invoice.preventDefault();
        setRemoveModal((value) => !value);
    }

    return (
        <div className="page-content">
            <DeleteModal showModal={removeModal} setModal={setRemoveModal} onHandleDelete={onDelete} />
            <Container fluid>
                <Breadcrumbs title="Events" breadcrumbItem="Event Details" />
            </Container>
            <Col>
                <Card>
                    <CardHeader>
                        <DataFlow isSubmitting={false} onEdit={onEdit} onDelete={onDelete} isEditMode={false} formName={"invoice-form"} />
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="flex-shrink-0" >
                                    <img src={getImageFullPath(event.imageUrl)} className="img-fluid" alt="" />
                                </div >
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={10}>
                                <Row >
                                    <Col md={6} sm={6}  >
                                        <MCLabel label='Date' value={event.date} />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col>
                                        <h5 className="font-size-15 mb-1">Title: </h5>
                                        <p className="text-muted">{event.title}</p>
                                    </Col>
                                    <Col>
                                        <div style={{ textAlign: "right" }} >
                                            <h5 className="d-flex justify-content-end font-size-15 mb-1">العنوان: </h5>
                                            <p className="text-muted">{event.titleAr}</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <h5 className="font-size-15 mb-1">Description: </h5>
                                        <p className="text-muted">{event.description}</p>
                                    </Col>
                                    <Col>
                                        <div style={{ textAlign: "right" }} >
                                            <h5 className="font-size-15 mb-1">الوصف: </h5>
                                            <p className="text-muted">{event.descriptionAr}</p>
                                        </div>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}


export const loader = async ({ params }: any) => {
    if (params.eventId) {
        const event = await get(Number(params.eventId))
        return event;
    }

    return null;
}

export const action = async ({ request, params }: any) => {
    let eventId: number = Number(params.eventId);
    await remove(eventId);
    return redirect(`/events`);
}

export default EventView;