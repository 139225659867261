
export enum ECurrency {
    Dinar = 7,
    Dollar= 8,
}

interface CurrencyType {
    [key: number]: {
        symbol: string
    }
}

export const CurrencyTypeObject: CurrencyType = {
    //Dollar
    7: {
        symbol: "IQD"
    },
    
    8: {
        symbol: "USD"
    },
}