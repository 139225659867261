
import { IPagination, IUnitBasicList, IApiResponse, IUnitResidentList, IUnitList, UnitInput, IPostResponse } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "units";
const LIST_BASIC_URL = BASE_RESOURCE + "/list-basic";
const LIST_URL = BASE_RESOURCE + "/list";
const LIST_RESSIDENT_URL = BASE_RESOURCE + "/list-resident";
const GET_URL = BASE_RESOURCE + "/{id}/get";
const ADD_URL = BASE_RESOURCE + "/add";
const UPDATE_URL = BASE_RESOURCE + "/{id}/update";
const DELETE_URL = BASE_RESOURCE + "/{id}/delete";

const api = new appApi();

export const listBasic = (): Promise<IApiResponse<IUnitBasicList[]>> => {
    return api.list<IUnitBasicList>(LIST_BASIC_URL, {} as IPagination, { "lid": 1 })
}

export const listResident = (): Promise<IApiResponse<IUnitResidentList[]>> => {
    return api.list<IUnitResidentList>(LIST_RESSIDENT_URL, {} as IPagination, { "lid": 1 })
}

export const list = (pagination: IPagination): Promise<IApiResponse<IUnitList[]>> => {
    return api.list<IUnitList>(LIST_URL, {} as IPagination, { "lid": 1 })
}


export const get = async (id: number): Promise<IApiResponse<UnitInput>> => {
    const url = GET_URL.replace("{id}", id.toString());
    return await api.get<UnitInput>(url);
}

export const add = async (event: UnitInput): Promise<number> => {
    const response = await api.post<UnitInput, IPostResponse>(ADD_URL, event);
    return response.data.id as number;
};

export const update = async (id: number, event: UnitInput) => {
    const url = UPDATE_URL.replace("{id}", id.toString());
    await api.update<UnitInput, {}>(url, event);
    return;
};

// export const remove = async (id: number) => {
//     const url = DELETE_URL.replace("{id}", id.toString());
//     await api.delete(url);
//     return;
// };