import { useQuery } from '@tanstack/react-query';
import { listBasic as loadUnits } from "../../services/apiUnit";
import { listBasic as loadServices } from "../../services/apiService";
import { list as loadLookUps } from "../../services/apiMisc";
import { ELookUpType } from "../../constants/index";
import { IUnitBasicList, IServiceBasicList, ILookUp } from "../../types/index";
import { useEffect, useState } from 'react';

function useRequestData() {

    const [sessionId, setSessionid] = useState("");

    ///////// methods
    const fetchUnits = async () => {
        const response = await loadUnits();
        return response.data;
    };

    const fetchServices = async () => {
        const response = await loadServices();
        return response.data;
    };

    const fetchStatuses = async (): Promise<ILookUp[]> => {
        const response = await loadLookUps(ELookUpType.REQUEST_STATUS);
        return response;
    };

    useEffect(() =>{
        setSessionid(crypto.randomUUID());
    }, [])
    const { data: units } = useQuery<IUnitBasicList[], Error>({ queryKey: ["basic", "units"], queryFn: () => fetchUnits() })
    const { data: services } = useQuery<IServiceBasicList[], Error>({ queryKey: ["basic", "services"], queryFn: () => fetchServices() })
    const { data: rStatues } = useQuery<ILookUp[], Error>({ queryKey: ["basic", "request", "status"], queryFn: () => fetchStatuses() || [] })

    return { sessionId, units, services, rStatues }
}

export default useRequestData