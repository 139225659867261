import * as Yup from "yup";

export const requestSchema = Yup.object()
    .shape({
        invNo: Yup.string().default(""),
        date: Yup.date().default(new Date()).required("Please Enter Date").typeError("Please Enter Date"),
        refNo: Yup.string().default(""),
        unitId: Yup.number().default(0).moreThan(0, "Please enter unit").required("Please enter unit").typeError("Please enter a valid unit"),
        serviceId: Yup.number().default(0).moreThan(0, "Please enter service").required("Please enter unit"),
        statusId: Yup.number().default(0).moreThan(0, "Please enter service").required("Please enter status"),
        description: Yup.string().default(""),
    });

export type RequestInput = Yup.InferType<typeof requestSchema>;

export interface IRequestList {
    id: number,
    date: Date,
    invNo: string,
    unitId: Number,
    unitFullNumber: string,
    service: string,
    statusId: number,
    status: string,
}