import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
//import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import AccountSelector from "../CommonForBoth/TopbarDropdown/AccountSelector";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Reactstrap
import { Offcanvas } from "reactstrap";

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";

const Header = (props: any) => {

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box">
              <Link to="/events" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="22" />
                </span>
              </Link>

             
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>


          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block">

              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-2">
                  <div className="search-box">
                    <div className="position-relative">
                      <input type="text" className="form-control rounded bg-light border-0" placeholder="Search" />
                      <i className="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            
            
            <LanguageDropdown />
            <AccountSelector />
            <ProfileMenu />
          </div>
        </div>
      </header>

      <Offcanvas isOpen={openDrawer} onClosed={onDrawerClose} direction={"end"}>
        <RightSidebar
          onClosed={onDrawerClose}
        />
      </Offcanvas>

    </React.Fragment>
  );
};

export default withTranslation()(Header);