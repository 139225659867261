
interface MCLabelProps {
    label: string
    value: any
}

export const MCLabel = ({ label, value }: MCLabelProps) => {
    return (
        <>
            <h5 className="font-size-15 mb-1">{label}</h5>
            <p className="text-muted">{value}</p>
        </>
    )
}
