import { useEffect, useState } from 'react'
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Option,
    Combobox,
    ComboboxProps,
    makeStyles, shorthands, Button
} from "@fluentui/react-components";

import {
    DeleteRegular,
} from "@fluentui/react-icons";

import type { UseFormSetValue, UseFormGetValues, FieldValues, Path } from "react-hook-form";
import { Controller, useFieldArray } from "react-hook-form";

import useInputUnitData from "../../../hooks/useInputUnitData";
import { Card, CardBody, CardHeader, } from 'reactstrap';
import { IUnitResidentList } from 'src/types';
import { MCBaseInputProp } from './MCProps';


const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        ...shorthands.gap("2px"),
        maxWidth: "300px",
    },
    listbox: {
        maxHeight: "300px",
        width: "550px"
    },
    option: {
        height: "32px",
    },
});

interface MCInputUserUnitProps<T extends FieldValues> extends MCBaseInputProp<T>, ComboboxProps {
    setValue: UseFormSetValue<T>,
    getValues: UseFormGetValues<T>,
    control: any,
}

const columns = [
    { columnKey: "unitNumber", label: "Unit Number" },
    { columnKey: "zone", label: "Zone" },
    { columnKey: "building", label: "Building" },
    { columnKey: "name", label: "Name" },
];

export const MCInputUserUnit = <T extends FieldValues>({ fieldName, placeholder, registerForm, control, setValue, getValues, registerProps, errors, ...props }: MCInputUserUnitProps<T>) => {
    const { units } = useInputUnitData();
    const [inputUnits, setInputUnits] = useState<IUnitResidentList[]>([]);
    const unitList = !inputUnits ? (units ? units : []) : (units ? units : []).filter(unit => !inputUnits.some(x => x.id === unit.id));

    const [selectedOptions, setSelectedOptions] = useState<string[]>([""]);
    const { append, remove } = useFieldArray(
        {
            control,
            name: fieldName
        }
    );

    useEffect(() => {
        const inputUserUnitIds = getValues("units" as Path<T>);
        if (inputUserUnitIds) {
            let unitsToBeAdded: IUnitResidentList[] = [];
            inputUserUnitIds.forEach((unitId: number) => {
                const selectedunit = units?.find(x => x.id === unitId);
                if (selectedunit) {
                    unitsToBeAdded.push(selectedunit);
                }
            });

            setInputUnits(() => unitsToBeAdded);
        }
    }, [units]);

    const onOptionSelect: (typeof props)['onOptionSelect'] = (ev, data) => {
        setSelectedOptions(data.selectedOptions);
        const unitId = Number(data.selectedOptions[0]);

        if (unitId) {
            const selectedunit = unitList?.find(x => x.id === unitId);
            if (selectedunit) {
                setInputUnits(() => [...inputUnits, selectedunit])
                append(selectedunit.id);
            }
        }
        setSelectedOptions([]);
    };

    const deleteHandler = (event: any, unitId: number) => {
        event.preventDefault();
        setInputUnits(() => [...inputUnits.filter(x => x.id !== unitId)]);

        const inputUserUnitIds = getValues("units" as Path<T>);
        if (inputUserUnitIds) {
            const selectedItem = inputUserUnitIds.find((x: number) => x === unitId);
            const itemIndex = inputUserUnitIds.indexOf(selectedItem);
            remove(itemIndex);
        }
    }

    const styles = useStyles();
    return (
        <Card>
            <CardHeader>
                <Controller control={control} name={fieldName}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Combobox
                            {...props}
                            ref={ref}
                            onChange={onChange}
                            selectedOptions={selectedOptions}
                            onOptionSelect={onOptionSelect}
                            placeholder="Enter unit number"
                            value={""}
                            size="medium"
                            width={400}
                            listbox={{ className: styles.listbox }}
                        >
                            {unitList.map((unit) => (
                                <Option key={unit.id} text={unit.unitNumber} value={unit.id.toString()} disabled={unit.loginName !== ""}>
                                    <div>{unit.unitNumber}</div>
                                    <div >{unit.name}</div>
                                </Option>
                            ))}
                        </Combobox>
                    )}
                />

            </CardHeader>
            <CardBody>
                <Table arial-label="Default table" >
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell key={column.columnKey}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody  >
                        {(!inputUnits ? [] : inputUnits).slice(0, 5).map((unit) => (
                            <TableRow key={unit.id} >
                                <TableCell>
                                    <TableCellLayout>
                                        {unit.unitNumber}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout>
                                        {unit.zone}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {unit.building}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell>
                                    <TableCellLayout>
                                        {unit.name}
                                    </TableCellLayout>
                                </TableCell>

                                <TableCell role="gridcell">
                                    <TableCellLayout>

                                        <Button onClick={(e) => deleteHandler(e, unit.id)} icon={<DeleteRegular />}>Delete</Button>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </CardBody>
        </Card>
    )
}
