
const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE_URL;

export const range = (start: number, end: number) => {
    return [...Array(end - start + 1)].map((_, idx) => start + idx);
}

export const getImageFullPath = (relativePath: string | undefined): string => {

    return relativePath ? IMAGE_BASE + relativePath : "";
}

export const getQueryParams = (key: string): any => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(key);
}

export const refreshPage = (queryString: string) => {
    var newUrl = window.location.origin + window.location.pathname + (queryString ? "?" + queryString : "");
    window.location.href = newUrl;
    return false;
}