import { useQuery } from '@tanstack/react-query';
import { listBasic as loadProperties } from "../../services/apiAccountProperty";
import { IAccountProperties, IItemList } from "../../types/index";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { unitSchema, UnitInput, IApiResponse } from "../../types/index";

function useUnitData(initValues : any) {

    ///////// methods
    const fetchProperties = async () => {
        const response = await loadProperties();
        return response.data;
    };

    const { data: properties, isLoading } = useQuery<IAccountProperties[], Error>({ queryKey: ["basic", "units"], queryFn: () => fetchProperties() })
    const zones: IItemList[] = [...new Set(properties?.map(x => ({
        id: 0,
        name: x.zone
    })))];

    const buildings: IItemList[] = [...new Set(properties?.map(x => ({
        id: 0,
        name: x.building
    })))];
 
    const { control, register, handleSubmit, setValue, formState: { errors }, reset } = useForm<UnitInput>({
        resolver: yupResolver(unitSchema),
        defaultValues: initValues,
    });


    return { isLoading, control, register, handleSubmit, setValue, errors, reset, zones, buildings }
}

export default useUnitData