
import { IPagination, ILookUp } from "../types/index";
import { appApi } from "./api/api";

const BASE_RESOURCE = "misc";
const LIST_URL = BASE_RESOURCE + "/list";

const api = new appApi();

type lookUpDic = {
    [key: string]: ILookUp[],
}

let lookups: lookUpDic = {};

export const list = async (type: string): Promise<ILookUp[]> => {
    
    const data = await api.list<ILookUp>(LIST_URL, {} as IPagination);

    if (!lookups[type]) {
        // Populating the lookups object using reduce
        const populatedLookups = data.data.reduce((acc, cur) => {
            if (!acc[cur.type]) {
                acc[cur.type] = [];
            }
            acc[cur.type].push(cur);
            return acc;
        }, lookups);
        lookups = populatedLookups;
    }

    return lookups[type];
}