import { useEffect } from "react";
import dayjs from 'dayjs';

import { redirect, Form, useNavigation, useSubmit, useLoaderData, useParams } from "react-router-dom";

import { requestSchema, RequestInput, IApiResponse } from "../../types/index";
import { add, update, get } from "../../services/apiRequest";

import useRequestData from "./hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Card, CardBody, CardHeader, Col, Row, FormGroup } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataFlow, MCText, MCCombobox, MCTextAria, MCDate } from "../../components/CommonForBoth/Entries/index";
import { Input, Field, makeStyles, Option } from '@fluentui/react-components';

const useStyles = makeStyles({
    option: {
        height: "32px",
    },
});

const RequestDetail = () => {
    document.title = "Request Detail | Fatoura - Admin";

    const styles = useStyles();
    const { requestId } = useParams();
    const navigation = useNavigation();
    const submit = useSubmit();

    const isSubmitting = navigation.state === "submitting";
    const { data: request } = useLoaderData() as IApiResponse<RequestInput> || {};
    const initValues = requestSchema.cast(request || {});
    const { control, register, getValues, handleSubmit, setValue, formState: { errors } } = useForm<RequestInput>({
        resolver: yupResolver(requestSchema),
        defaultValues: initValues,
    });

    const { sessionId, units, services, rStatues } = useRequestData();

    ///////// methods

    //commands
    const onSubmit = (data: any, e: any) => {
        const method = !requestId || Number(requestId) === 0 ? "post" : "put";
        data.sessionId = sessionId;
        submit(data, { method: method });
    };


    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Requests" breadcrumbItem="Request Details" routePath="/requests"/>
            </Container>
            <Card>
                <CardHeader>
                    <DataFlow isSubmitting={isSubmitting} isEditMode={true} formName={"request-form"} />
                </CardHeader>
                <CardBody>
                    <Form id="request-form" className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <>
                            
                            <Row className="gap-3mt-n1">
                                <Col >
                                    <FormGroup>
                                        <Field label="Inv #:">
                                            <Input name="invNo" placeholder="####" className="form-control" id="invno"
                                                type="text" value={request ? request.invNo : ""} disabled={true} />
                                        </Field>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <MCCombobox fieldName="statusId" label="Status" placeholder="Select status" control={control} items={rStatues || []}
                                        setValue={setValue} registerForm={register} errors={errors}>
                                        {(rStatues || []).map((status) => (
                                            <Option key={status.id}
                                                text={status.name} value={status.id.toString()}
                                                className={styles.option}>
                                                {status.name}
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                            </Row>

                            <Row className="gap-3mt-n1">
                                <Col >
                                    <MCDate label="Date" fieldName="date" placeholder="Select a date" className="form-control" id="date"
                                        registerForm={register} errors={errors} registerProps={{ valueAsDate: true }} selectedDateValue={initValues.date} setValue={setValue}
                                        disabled={true} />
                                </Col>
                                <Col >
                                    <MCText label="Ref. #:" fieldName="refNo" placeholder="enter reference # here"
                                        className="form-control" type="text"
                                        registerForm={register} errors={errors} disabled={true} />
                                </Col>

                            </Row>

                            <Row >
                                <Col md={6} sm={6}  >
                                    <MCCombobox fieldName="serviceId" label="Service" placeholder="Select service" control={control} items={services || []}
                                        setValue={setValue} registerForm={register} errors={errors} disabled={true}>
                                        {(services || []).map((service) => (
                                            <Option key={service.id}
                                                text={service.name} value={service.id.toString()}
                                                className={styles.option}>
                                                {service.name}
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                                <Col>
                                    <MCCombobox fieldName="unitId" label="Unit*" placeholder="Select unit" registerForm={register} control={control} setValue={setValue} errors={errors}
                                        items={units || []} disabled={true}
                                    >
                                        {(units || []).map((unit) => (
                                            <Option key={unit.id} text={unit.unitNumber} value={unit.id.toString()} className={styles.option}>
                                                <div >{unit.unitNumber}</div>
                                                <div >{unit.zone}</div>
                                                <div >{unit.building}</div>
                                            </Option>
                                        ))}
                                    </MCCombobox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MCTextAria label="Description" fieldName="description" placeholder="Enter description here" className="form-control" id="description"
                                        registerForm={register} errors={errors} disabled={true} />
                                </Col>
                            </Row>
                        </>
                    </Form>
                    {/* <DevTool control={control} /> */}
                </CardBody>
            </Card>
        </div >
    );
}

export const action = async ({ request, params }: any) => {
    const formData = await request.formData();

    const data: any = Object.fromEntries(formData);
    const sessionId = data.sessionId;


    const newRequest: RequestInput = {
        date: dayjs(data.date).toDate(),
        refNo: data.refNo,
        serviceId: Number(data.serviceId),
        unitId: Number(data.unitId),
        description: data.description,
        statusId: Number(data.statusId),
        invNo: ""
    };

    let requestId: number = Number(params.requestId);
    if (request.method === "POST") {
        const id = await add(newRequest, sessionId);
        requestId = id;
    } else if (request.method === "PUT") {
        await update( requestId, newRequest as RequestInput, sessionId);
    }
    return redirect(`/requests/${requestId}`);

}

export const loader = async ({ params }: any) => {
    if (params.requestId && Number(params.requestId)) {
        const event = await get(Number(params.requestId))
        return event;
    }

    return null;
}


export default RequestDetail