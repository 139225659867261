import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminUser, IAccount } from "../../../types/index";

import { GetLocal, LocalKeys } from "../../../services/localStorage";

interface LoginStateType {
    userProfile: object,
    error?: string | object | null | undefined | unknown,
    user: IAdminUser,
    selectedAccount?: IAccount
    history?: any
}

export const initialState: LoginStateType = {
    user: GetLocal(LocalKeys.AUTH_USER_KEY),
    userProfile: {},
    error: '',
    history: '',
    selectedAccount: GetLocal(LocalKeys.GLOBAL_ACC_KEY)
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        apiError(state, action) {
            state.error = action.payload
        },
        loginSuccess(state: LoginStateType, action: PayloadAction<IAdminUser>) {
            state.user = action.payload
        },
        logoutUserSuccess(state, action) {
            state.history = action.payload
        },
        setGlobalAccount(state, action: PayloadAction<IAccount>) {
            state.selectedAccount = action.payload;
        },
    },
});


export const {
    apiError,
    loginSuccess,
    logoutUserSuccess,
    setGlobalAccount
} = loginSlice.actions

export default loginSlice.reducer;