
import { createSelector } from "reselect";
import { RootState } from "src/slices";
import { IAccount } from "../../../types/index";

const loggedInUser = (state: RootState) => state.login;

export const userSelector = createSelector(loggedInUser, loggedInUser => loggedInUser.user);

export const selectedAccountSelector = createSelector(loggedInUser, (l) => {
    return l.selectedAccount || {} as IAccount;
});
