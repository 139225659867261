import { useMemo } from "react";
import dayjs from 'dayjs';
import { useLoaderData, Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import type { ColumnDef } from "@tanstack/react-table/src";
import { useNavigate } from "react-router-dom";
import { list as listInvoice } from "../../services/apiInvoice";

import { IApiResponse, IPagination, IInvoiceList } from "../../types/index";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "src/components/Common/TableContainer";
import { StatusIcon, NumberFormatCol } from "../../components/CommonForBoth/Entries/index";

const Events = () => {
    document.title = "Invoices | Fatoura - Admin";
    const { data: list } = useLoaderData() as IApiResponse<IInvoiceList[]>;
    const navigate = useNavigate();

    const columns = useMemo<ColumnDef<IInvoiceList>[]>(
        () => [
            {
                accessorKey: "invNo",
                id: 'invNo',
                //cell: info => info.getValue(),
                cell: ({ cell }: any) => <Link to={`/invoices/${cell?.row?.original.id}`} className="medium">{`${cell.getValue()}`}</Link>,
                header: () => <span>InvNo</span>,
                footer: props => props.column.id,
                size: 100
            },

            {
                header: () => <span>Date</span>,
                accessorKey: "date",
                id: 'Date',
                cell: props => dayjs(props.getValue() as Date).format("DD/MM/YYYY"),
                size: 50
                //footer: props => props.column.id,
            },
            {
                accessorKey: "service",
                id: 'service',
                cell: info => info.getValue(),
                header: () => <span>Service</span>,
                footer: props => props.column.id,
                size: 100
            },

            {
                header: () => <span>Total Price</span>,
                accessorKey: "totalPrice",
                id: 'totalPrice',
                //cell: props => Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(props.getValue())),
                cell: props  => <NumberFormatCol currencyId={props.cell.row.original.currencyId} value={props.getValue() as number}/>,
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },

            {
                header: () => <span>Unit Number</span>,
                accessorKey: "unitFullNumber",
                id: 'unitFullNumber',
                cell: props => props.getValue(),
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
            {
                header: () => <span>Status</span>,
                accessorKey: "status",
                id: 'status',
                cell: ({ cell }: any) => <StatusIcon statusId={cell?.row?.original.statusId} statusName={cell.getValue()} />,
                //icon: "mdi mdi-check-circle-outline text-success",
                filterFn: "includesString",
                enableSorting: false,
                size: 30
                //footer: props => props.column.id,
            },
        ],
        [])

    const handleAddNewClick = () => {
        navigate('/invoices/new');
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Invoices" breadcrumbItem="List" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {<TableContainer
                                    columns={columns}
                                    data={list}
                                    isGlobalFilter={true}
                                    isAddNewList={true}
                                    addNewText={"Add new invoice"}
                                    handleAddNewClick={handleAddNewClick}
                                    customPageSize={1}
                                    className="custom-header-css"
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export const loader = async () => {

    const pagination: IPagination = {
        pageNo: 0,
        pageSize: 10
    }

    const list = await listInvoice(pagination);
    
    if (!list || list.data === null) {
        const empty: IApiResponse<IInvoiceList[]> = {} as IApiResponse<IInvoiceList[]>;
        empty.data= [];
        return empty;
      }
    
      return list;
}

export default Events;
