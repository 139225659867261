import { useQuery } from '@tanstack/react-query';
import { listResident as loadUnits } from "../services/apiUnit";
import { IUnitResidentList } from "../types/index";

function useInputUnitData() {

    ///////// methods
    const fetchUnits = async () => {
        const response = await loadUnits();
        return response.data;
    };

    const { data: units } = useQuery<IUnitResidentList[], Error>({ queryKey: ["basic", "units", "inputs"], queryFn: () => fetchUnits() })
    return { units }
}

export default useInputUnitData