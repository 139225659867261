import { MouseEventHandler, MouseEvent } from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface DataFlowProps {
    formName: string
    isSubmitting: boolean
    isEditMode: boolean
    //onCancel: MouseEventHandler
    onDelete?: MouseEventHandler
    onEdit?: MouseEventHandler
}

export const DataFlow = ({ isSubmitting, isEditMode, formName, onDelete, onEdit }: DataFlowProps) => {
    const navigate = useNavigate();

    const onCancelHanlder = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        //navigate("/users");
        navigate(-1);
    }
    return (
        <div className="d-flex justify-content-end">
            <div className="btn-group me-2 mb-2 mb-sm-0">
                <Button type="submit" color="primary" className="waves-light waves-effect" form={formName}
                    disabled={!isEditMode || isSubmitting}>
                    Save {" "}
                    <i className="fas fa-save" />
                </Button>
                <Button
                    type="button"
                    color="success"
                    className="waves-light waves-effect" disabled={!onEdit || isEditMode} onClick={onEdit} >
                    Edit {" "}
                    <i className="uil-edit-alt" />
                </Button>
                <Button
                    type="button"
                    color="danger"
                    className="waves-light waves-effect" disabled={!onDelete || isEditMode} onClick={onDelete}
                >
                    Delete {" "}
                    <i className="far fa-trash-alt" />
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    className="waves-light waves-effect" onClick={onCancelHanlder}
                    disabled={isSubmitting}>
                    Close {" "}
                    <i className="uil-cancel" />
                </Button>
            </div>
        </div>
    )
}
