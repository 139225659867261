
export enum InvoiceStatus {
    New = 25,
    Paid = 16,
    Pending = 12,
    Partial = 17,
    Overdue = 18,
    Cancelled = 19
}



interface StatusColorType {
    [key: number]: {
        className: string
    }
}
export const StatusColor: StatusColorType = {
    //Approve
    10: {
        "className": "mdi mdi-check-decagram text-success"
    },

    //pending
    9 : {
        "className": "mdi mdi-check-decagram text-warning"
    },

    //"Rejected"
    11: {
        "className": "mdi mdi-close-octagon text-danger"
    },

    //New
    25: {
        "className": "mdi mdi-new-box text-info"
    },
    //Paid
    16: {
        "className": "mdi mdi-check-circle-outline text-success"
    },

    //Pending
    12: {
        "className": "badge bg-warning-subtle text-warning p-2"
    },

    //New - Request
    13: {
        "className": "mdi mdi-new-box text-primary"
    },
    //Partial
    17: {
        "className": "mdi mdi-check-circle-outline text-success"
    },
    //Overdue
    18: {
        "className": "mdi mdi-close-circle-outline text-danger"
    },
    //Cancelled
    19: {
        "className": "mdi mdi-close-circle-outline text-danger"
    },

    //paid
    20: {
        "className": "mdi mdi-check-circle-outline text-success"
    },

    //"Cancelled"
    21: {
        "className": "mdi mdi-close-circle-outline text-danger"
    },

    //"Expired"
    22: {
        "className": "mdi mdi-close-circle-outline text-danger"
    },

    //"Failed"
    23: {
        "className": "mdi mdi-close-circle-outline text-danger"
    },

    

}
