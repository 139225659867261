import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { range } from "../../helpers/utils";

import {
  Column,
  //Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table'

import { PaginationState, ColumnFiltersState, FilterFn, ColumnDef } from '@tanstack/react-table/src'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'

import { Table, Row, Col, Button, Input, CardBody, PaginationItem, PaginationLink } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../Common/Filter";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import Icon from "@ailibs/feather-react-ts/dist/Icon";

declare module '@tanstack/react-table' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

interface GlobalFilterProps {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  isJobListGlobalFilter?: any;
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 200,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}: GlobalFilterProps) {

  //const count = preGlobalFilteredRows.length;
  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <DebouncedInput
                onChange={value => { setGlobalFilter(value); }}
                id="search-bar-0"
                type="text"
                className="form-control"
                //placeholder={`${count} records...`}
                value={globalFilter ?? ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isJobListGlobalFilter?: any;
  isAddNewList?: any;
  handleAddNewClick?: any;
  addNewText?: string;

  customPageSize?: any;
  className?: any;
  customPageSizeOptions?: any;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({ itemRank, })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const TableContainer = ({
  data,
  columns,
  isGlobalFilter,
  isJobListGlobalFilter,
  customPageSize,
  className,
  customPageSizeOptions,
  isAddNewList,
  handleAddNewClick,
  addNewText,

}: TableContainerProps) => {

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const table = useReactTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
      state: {
        columnFilters,
        globalFilter,
        pagination,
      },
      getCoreRowModel: getCoreRowModel(),
      columnResizeMode: "onChange",
      getFilteredRowModel: getFilteredRowModel(),

      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: fuzzyFilter,

      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onPaginationChange: setPagination,
    });

  const paginationNumberLength = 6;
  const groupNumer = ((pagination.pageIndex / paginationNumberLength) >> 0);
  const start = groupNumer * paginationNumberLength + 1;
  let end = start + paginationNumberLength - 1;
  if (end > table.getPageCount()) {
    end -= end - table.getPageCount()
  }
  const paginationNumbers = range(start, end);

  const generateSortingIndicator = (column: any) => {
    const sort: any = {
      asc: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 17h3l-4 4l-4-4h3V3h2zM7 3C4.79 3 3 4.79 3 7s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2m0 4c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4"></path>
      </svg>,
      desc: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 7h3l-4-4l-4 4h3v14h2M5 7c0 1.1.9 2 2 2s2-.9 2-2s-.9-2-2-2s-2 .9-2 2m2-4c2.21 0 4 1.79 4 4s-1.79 4-4 4s-4-1.79-4-4s1.79-4 4-4m0 10c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4"></path>
      </svg>
    }
    return sort[column.getIsSorted()];
  };

  const onChangeInSelect = (event: any) => {
    table.setPageSize(Number(event.target.value));
  };

  return (
    <>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pagination.pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 50, 100, 200].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            preGlobalFilteredRows={table.preGlobalFilteredRows}
          //isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}

        {isAddNewList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleAddNewClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                {addNewText}
              </Button>
            </div>
          </Col>
        )}

      </Row>


      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <thead className="table-light table-nowrap">
            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id} >
                {headerGroup.headers.map((header: any) => (
                  <th key={header.id} style={{ width: header.getSize(), position: "relative" }}>
                    <div className="xs-1" onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanSort() && generateSortingIndicator(header.column)}
                    </div>
                    <div onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()}
                      className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`} >
                    </div>
                    <Filter column={columns} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {/* {table.page.map((row: any) => { */}
            { table.getRowModel().rows.map((row: any) => {
              return (
                <Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td key={cell.id} style={{ width: cell.column.getSize() }}>
                          {
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div >
      <Row className="g-0">
        <Col sm={6}>
          <div>
            <p className="mb-sm-0">Page {pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}</p>
          </div>
        </Col>
        <Col sm={6}>
          <div className="float-sm-end">
            <ul className="pagination pagination-rounded mb-sm-0">
              <PaginationItem className="page-item" onClick={e => {
                e.preventDefault();
                table.firstPage();
              }} disabled={!table.getCanPreviousPage()}>
                <PaginationLink href="#" className="page-link"><i className="mdi mdi-chevron-double-left"></i></PaginationLink>
              </PaginationItem>

              <PaginationItem className="page-item" onClick={e => {
                e.preventDefault();
                table.previousPage();
              }} disabled={!table.getCanPreviousPage()}>
                <PaginationLink href="#" className="page-link"><i className="mdi mdi-chevron-left"></i></PaginationLink>
              </PaginationItem>
              {
                paginationNumbers.map((n, i) => (
                  <PaginationItem className={`page-item ${pagination.pageIndex === n - 1 ? "active" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      table.setPageIndex(n - 1);
                    }} key={n}>
                    <PaginationLink href="#" className="page-link">{n}</PaginationLink>
                  </PaginationItem>
                ))}
              <PaginationItem className="page-item" onClick={(e) => {
                e.preventDefault();
                table.nextPage()
              }} disabled={!table.getCanNextPage()}>
                <PaginationLink href="#" className="page-link"><i className="mdi mdi-chevron-right"></i></PaginationLink>
              </PaginationItem>
              <PaginationItem className="page-item" onClick={(e) => {
                e.preventDefault();
                table.lastPage()
              }} disabled={!table.getCanNextPage()}>
                <PaginationLink href="#" className="page-link"><i className="mdi mdi-chevron-double-right"></i></PaginationLink>
              </PaginationItem>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
